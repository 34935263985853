import React, { useState, useEffect, useRef, useReducer } from 'react';
import 'react-tabs/style/react-tabs.css';
import TextInput from 'components/form-input/TextInput';
import { useMixpanel } from 'react-mixpanel-browser';
import {
  orderPrognostogram,
  clearOrderFormData,
  modifyPrognostogramOrder,
  resetOrderIndexData,
  uploadOrderDocument,
  resetUploadDocumentData,
  getMotivations,
  resetPhenotypeData,
  resetPicoOptionsData,
  resetPicoData,
  resetChartrwdErrors,
  getorderByGuid,
  resetSingleOrderData,
  setChatrwdLoaderCloseClicked,
  fetchOrderTaskStatus,
  removeUploadedFile,
  clearUploadDocumentsList,
  setUploadedFile,
  getorderByGuidError,
  resetSuggestions,
  handleOrderDetailModal,
  resetDownloadLink,
} from 'redux/modules/orderDetails/actionsv1';
import { dateFormatyyyymmdd, dateFormatmmddyyyy } from '../utils/dateFormats';
import { getRequesters } from 'redux/modules/userProfile/actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getRawToken, validateEmail } from '../utils/validations';
import urlConstantsEnum from '../constants/enum/urlConstantsEnum';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import AuthLoader from 'components/AuthLoader';
import OrderPrognostogramHeader from 'components/OrderPrognostogramHeader';
import { Strings } from '../constants/Strings';
import CustomModal from '../components/CustomModal';
import { VscChromeClose } from 'react-icons/vsc';
import TextArea from 'components/form-input/TextArea';
import { ButtonCustom, ButtonLink } from 'components/form-input/Button';
import { INPUT_SIZE, PICOT_QUESTION_LINK } from 'constants/Constants';
import { ImInfo } from 'react-icons/im';
import { HiOutlineChevronRight, HiOutlineChevronDown } from 'react-icons/hi';
import Checkbox from 'components/form-input/Checkbox';
import orderFormErrorStr from 'constants/errorStrings';
import Cookies from 'js-cookie';
import { MdError } from 'react-icons/md';
import { getSubscriptionDataset } from 'redux/modules/userSubscription/actions';
import {
  getPhysicianInstituteList,
  getUsersConfig,
  requestGetUserProfile,
} from 'redux/modules/userProfile/actions';
import RadioButton from '../components/form-input/RadioButton';
import AIWorkflow from '../components/AIWorkflow';
import ChatrwdOrderLoader from '../components/ChatrwdOrderLoader';
import { AiFillDelete } from 'react-icons/ai';
import { RiErrorWarningFill } from 'react-icons/ri';
import OrderdetailModal from 'components/OrderdetailModal';
import { resetOrderNotificationDetails } from 'redux/modules/orderDetails/actions';
import resourceEnum from 'constants/enum/resourceEnum';
import DropdownMenu from 'components/dropdowns/DropdownMenu';

function motivationRequiresPatientData(providerMotivation) {
  return providerMotivation && providerMotivation.label === 'Patient Care';
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}

const picoInitialState = {
  isSuggestImprovmentClicked: false,
  selectedSuggestionsOptions: [],
  loadingTitle: Strings.generatingPicotMessage,
  suggestionPicodata: {
    population: '',
    intervention: '',
    control: '',
    outcome: '',
    timeframe: '',
  },
  userAction: {
    action: undefined,
    options: undefined,
  },
  isClinicalRetry: false,
};

const suggestionReducer = (state, action) => {
  switch (action.type) {
    case 'suggestImprovementsClicked':
      return {
        ...state,
        isSuggestImprovmentClicked: true,
      };
    case 'setPICOData':
      return {
        ...state,
        suggestionPicodata: action.payload,
      };
    case 'setSuggestionsOptions':
      const dataArray = Object.entries(action.payload);
      dataArray.sort((a, b) => {
        const numA = parseInt(a[1].split('.')[0]);
        const numB = parseInt(b[1].split('.')[0]);
        return numA - numB;
      });
      const sortedData = {};
      dataArray.forEach((item, index) => {
        sortedData[index] = item[1];
      });
      return {
        ...state,
        selectedSuggestionsOptions: [...state.selectedSuggestionsOptions, sortedData],
      };
    case 'removelastSuggestionsOptions':
      const option = [...state.selectedSuggestionsOptions];
      option.pop();
      return {
        ...state,
        selectedSuggestionsOptions: option,
      };
    case 'setSelectedOptions':
      return {
        ...state,
        selectedSuggestionsOptions: action.payload,
      };
    case 'setLoadingTitle':
      return {
        ...state,
        loadingTitle: action.payload,
      };
    case 'setUserAction':
      return {
        ...state,
        userAction: {
          action: action.payload.userAction,
          options: action.payload.options,
        },
      };
    case 'setIsClinicalRetry':
      return {
        ...state,
        isClinicalRetry: action.payload,
      };
    case 'resetPICOState':
      return {
        ...picoInitialState,
      };
    default:
      return state;
  }
};

const SinglePageOrderRequest = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  let history = useHistory();
  let { path: mainPath } = useRouteMatch();
  const orderTypePath = mainPath.split('/')[2];
  const ref = useRef(null);
  let intervalId = useRef(null);
  const reftooltip = useRef(null);
  const dataSourceDivRef = useRef(null);
  const refConfidential = useRef(null);
  const refMotivation = useRef(null);
  const refRequester = useRef(null);
  const refPHI = useRef(null);

  const location = useLocation();
  const params = useParams();
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAITooltip, setShowAITooltip] = useState(false);
  const [showRequesterTooltip, setShowRequesterTooltip] = useState(false);
  const [showMotivationTooltip, setShowMotivationTooltip] = useState(false);
  const [showPHIToolTip, setShowPHITooltip] = useState(false);

  const [showTooltipConfidential, setShowTooltipConfidential] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [formErrors, setFormErrors] = useState('');
  const [errorsObject, setFormErrorsObject] = useState({});
  const [selectedPreviousRequestor, setSelectedPreviousRequestor] = useState(null);
  const [questionTypeSelection, setQuestionTypeSelection] = useState(1);

  const [isOrderSubmitButtonClicked, setOrderSubmitButtonClicked] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { userData, updateProfileError, requesters, getRequestersError, usersConfig } =
    useSelector(({ userProfile }) => userProfile);

  const {
    isOrderLoading,
    orderPrognostogramError,
    orderFormData,
    orderScopeData,
    uploadedDocumentInfo,
    isUploadingDocument,
    getDocumentUploadError,
    fetchedDocument,
    isFetchingDocumentStarted,
    getDocumentError,
    motivations,
    openAiResponse,
    savedPicoGuid,
    picotData,
    singleOrderData,
    isChatrwdLoaderCloseClicked,
    orderTaskStatusError,
    uploadedFilesList,
    getOrderDetailError,
    PICOGuid,
    questionData,
    orderNotificationDetailsLoading,
    orderNotificationDetails,
    orderNotificationDetailsError,
    pdfFile,
    isNotificationModalOpen,
    fileDowloadLink,
    resource,
    isgettingOrderDetails,
    studyFormat,
    picoSummaryQuestion,
  } = useSelector(({ orderDetails }) => orderDetails);

  function getInitialMotivation(motivations) {
    if (motivations && motivations.length) {
      const presentMotivations = motivations.filter((motivation) => motivation.value);
      if (presentMotivations.length) {
        const { category } = presentMotivations[0];
        return { label: category, value: category };
      }
    }
    return null;
  }

  const [selectedProviderMotivation, setSelectedProviderMotivation] = useState(
    getInitialMotivation(motivations),
  );

  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const {
    first_name = '',
    email = '',
    last_name = '',
    institute = '',
    guid = '',
  } = userData || {};

  const [isDocumentUploadClicked, setIsDocumentUploadClicked] = useState(false);
  const [isShowPhiModal, setIsShowPhiModal] = useState(false);
  const [documentFileName, setDocumentFileName] = useState(
    orderFormData?.documentFileName ? orderFormData?.documentFileName : '',
  );
  const [documentFile, setDocumentFile] = useState();
  const [phiDisclaimer, setPhiDisclaimer] = useState(
    orderFormData?.phiDisclaimer ? orderFormData?.phiDisclaimer : false,
  );
  const [documentError, setDocumentError] = useState('');

  const [filePath, setFilePath] = useState('');

  const [isPhenotypeResponse, setPhenotypeResponse] = useState(false);
  const [phenotypesResponseObject, setPhenotypesResponseObject] = useState('');

  const [dataTypeValue, setDatatypeValue] = useState(
    orderFormData?.data_source ? orderFormData?.data_source : '',
  );
  const [dataTypeObjValue, setDatatypeObjValue] = useState('');
  const [sharingValue, setSharingValue] = useState('Library');
  const urgencyTypeList = ['Not Urgent', 'Urgent'];
  const [urgencyType, setUrgencyType] = useState('Not Urgent');
  const [patientBirthDate, setPatientBirthDate] = useState('');
  const [generalStudyParams, setGeneralStudyParams] = useState();
  const [isShowLeftSection, setIsShowLeftSection] = useState(true);
  const [patientDatePickerIsOpen, setPatientDatePickerIsOpen] = useState(false);
  const [ShouldShowBirthdayError, setShouldShowBirthdayError] = useState(false);
  const [requestOnBehalf, setRequestOnBehalf] = useState(false);
  const [isShowDataSetDropDown, setShowDataSetDropDown] = useState(false);
  const [aiQuestion, setAiQuestion] = useState('');
  const [showAiResult, setShowAiResult] = useState(false);
  const [activeButton, setActiveButton] = useState('');
  const [isManuallyEditPicoSelected, setIsManuallyEditPicoSelected] = useState(false);
  const [phenotypeTableResponseText, setPhenotypeTableResponseText] = useState('');
  const [secondActiveButton, setSecondActiveButton] = useState('');
  const [isConfidential, setConfidential] = useState(false);
  const [isMaxDocumentUploaded, setIsMaxDocumentUploaded] = useState(false);
  const [selectedMotivation, setSelectedMotivation] = useState({});
  const [suggestionsState, dispatchSuggestionAction] = useReducer(
    suggestionReducer,
    picoInitialState,
  );
  const [selectedDataSourceOption, setSelectedDataSourceOption] = useState([]);
  const [isQuestionClicked, setIsQuestionClicked] = useState(false);
  const { isSuggestImprovmentClicked } = suggestionsState;

  let initialState = {
    firstName: {
      value: '',
      isValid: true,
      errorText: orderFormErrorStr.firstName,
    },
    lastName: {
      value: '',
      isValid: true,
      errorText: orderFormErrorStr.lastName,
    },
    patientLastName: {
      value: '',
      isValid: true,
      errorText: orderFormErrorStr.patientLastName,
    },
    newEmail: {
      value: '',
      isValid: true,
      errorText: orderFormErrorStr.email,
    },
    questionText: {
      value: '',
      length: 0,
    },
    population: {
      value: '',
      length: 0,
    },
    intervention: {
      value: '',
      length: 0,
    },
    control: {
      value: '',
      length: 0,
    },
    outcome: {
      value: '',
      length: 0,
    },
    timeframe: {
      value: '',
      length: 0,
    },
    picotSwitch: {
      value: false,
    },
    initialQuestionText: {
      value: '',
    },
    question_title: {
      value: '',
      length: 0,
    },
    freeTextInitialQuestionText: {
      value: '',
    },
  };

  const setInitialStateforEdit = (formData) => {
    let initialState = {
      firstName: {
        value: formData?.requester?.first_name ? formData.requester.first_name : '',
        isValid: true,
        errorText: orderFormErrorStr.firstName,
      },
      lastName: {
        value: formData?.requester?.last_name ? formData.requester.last_name : '',
        isValid: true,
        errorText: orderFormErrorStr.lastName,
      },
      patientLastName: {
        value: formData?.patient?.last_name ? formData?.patient?.last_name : '',
        isValid: true,
        errorText: orderFormErrorStr.patientLastName,
      },
      newEmail: {
        value: formData?.requester?.email ? formData.requester?.email : '',
        isValid: true,
        errorText: orderFormErrorStr.email,
      },
      questionText: {
        value: formData?.clinicalQuestion
          ? formData.clinicalQuestion
          : formData?.clinical_questions
          ? formData.clinical_questions
          : '',
        length: formData?.clinicalQuestion ? formData?.clinicalQuestion.length : 0,
      },
      population: {
        value: formData?.population ? formData.population : '',
        length: formData?.population ? formData?.population.length : 0,
      },
      intervention: {
        value: formData?.intervention ? formData.intervention : '',
        length: formData?.intervention ? formData?.intervention.length : 0,
      },
      control: {
        value: formData?.control ? formData.control : '',
        length: formData?.control ? formData?.control.length : 0,
      },
      outcome: {
        value: formData?.outcome ? formData.outcome : '',
        length: formData?.outcome ? formData?.outcome.length : 0,
      },
      timeframe: {
        value: formData?.timeframe ? formData.timeframe : '',
        length: formData?.timeframe ? formData?.timeframe.length : 0,
      },
      picotSwitch: {
        value: formData?.picotSwitch ? formData.picotSwitch : false,
      },
      question_title: {
        value: formData?.question_title ? formData.question_title : '',
      },
      freeTextInitialQuestionText: {
        value: formData?.initial_clinical_questions,
      },
      initialQuestionText: {
        value: formData?.initial_clinical_questions || formData?.clinical_questions,
      },
    };

    setFormState(initialState);
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      ...provided,
      padding: '8',
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: 'none',
      border: '1px solid rgba(26, 26, 26, 0.5)',
      padding: '5px 0px 5px 16px',
    }),
    input: (provided, state) => ({
      ...provided,
      color: '#000',
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#000',
      backgroundColor: state.isFocused ? '#d8eeee' : '#fff',
      ':active': {
        backgroundColor: '#0D3F3C',
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  };

  const motivationCustomStyles = {
    ...customStyles,
    control: (provided, state) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: 'none',
      border:
        errorsObject.motivation && !selectedProviderMotivation
          ? `1px solid red`
          : '1px solid rgba(26, 26, 26, 0.5)',
      padding: '5px 0px 5px 16px',
    }),
  };

  const [formState, setFormState] = useState(initialState);

  const handleCheck = () => {
    mixpanel.track(
      'Confidential input ' + !isConfidential ? 'checked' : 'unchecked' + ' event',
    );
    setConfidential(!isConfidential);
  };

  const handleChange = (value, name) => {
    setFormErrors('');
    setFormState((formState) => ({
      ...formState,
      [name]: { ...formState[name], value: value.trim() },
    }));
  };

  const handleEmailChange = (value) => {
    setFormErrors('');
    setFormState((formState) => ({
      ...formState,
      newEmail: { ...formState.newEmail, value: value },
    }));
    mixpanel.track('Email changed event', {
      selected_email: value,
    });
  };

  const hasErrors = Object.values(errorsObject).some(
    (value) => value !== undefined && value !== '',
  );
  const isFormValid = () => {
    let errorText = '';
    const noFirstName = !formState?.firstName.value;
    const noLastName = !formState?.lastName.value;
    const noEmail = !formState?.newEmail.value;
    const noPatientLastName = !formState?.patientLastName.value;
    const noPatientBirthDate = !patientBirthDate;

    const userSelectedMotivation =
      selectedProviderMotivation && selectedProviderMotivation.label;

    const errorsObject = {};

    if (requestOnBehalf && noFirstName) {
      errorsObject.firstName = Strings.orderFirstNameError;
    }
    if (requestOnBehalf && noLastName) {
      errorsObject.lastName = Strings.orderLastNameError;
    }

    if (requestOnBehalf && !validateEmail(formState?.newEmail.value)) {
      errorsObject.email = orderFormErrorStr.email;
    }
    if (
      motivationRequiresPatientData(selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
    ) {
      const date = new Date(patientBirthDate);
      if (noPatientLastName) {
        errorsObject.patientLastName = Strings.patientLastNameError;
      }
      if (noPatientBirthDate && !isValidDate(date)) {
        errorsObject.patientBirthDate = Strings.patientBirthDateError;
      }
    }
    if (!userSelectedMotivation) {
      errorsObject.motivation = Strings.motivationError;
    }
    const dataTypeValues = dataTypeObjValue !== '' ? dataTypeObjValue.split(',') : [];
    if (
      selectedDataSourceOption?.length == 0 ||
      selectedDataSourceOption?.every((opt) => !opt.isChecked)
    ) {
      errorsObject.dataSourceSelectionError = Strings.dataSourceSelectionError;
    }
    if (questionTypeSelection === 3) {
      if (generalStudyParams.sex.length == 0) {
        errorsObject.gender = Strings.genderError;
      }
      // if(generalStudyParams.race.length == 0){
      //   errorsObject.race = Strings.raceError;
      // }
      // if(generalStudyParams.ethnicity.length == 0){
      //   errorsObject.ethnicity = Strings.ethnicityError;
      // }
      if (
        generalStudyParams.downsample === true &&
        (generalStudyParams.downsample_number < 100 ||
          generalStudyParams.downsample_number > 10000000)
      ) {
        return;
      }
      if (generalStudyParams.follow_up_max_value === 0) {
        return;
      }
    }
    if (datasetList && datasetList.length == 0) {
      errorsObject.dataSourceError = Strings.dataSourceError;
    }

    setFormErrorsObject(errorsObject);

    errorText = errorsObject ? Object.values(errorsObject)[0] : '';

    setFormErrors(errorText);
    return !errorText;
  };

  const submitOrder = async () => {
    const token = await getIdTokenClaims();
    if (formState.questionText.value && isFormValid() && !isUploadingDocument) {
      dispatch(resetOrderIndexData());
      setOrderSubmitButtonClicked(true);

      // var documentPath = '';
      // if (uploadedDocumentInfo?.key_path) {
      //   documentPath = uploadedDocumentInfo?.key_path;
      // } else if (
      //   orderFormData?.previous_order_guid &&
      //   orderFormData?.documentKeyPath !== ''
      // ) {
      //   documentPath = orderFormData?.documentKeyPath;
      // } else {
      //   documentPath = '';
      // }

      let documentPaths = [];
      if (uploadedFilesList && uploadedFilesList.length > 0) {
        uploadedFilesList.forEach((file) => {
          const obj = {
            key_path: file.key_path,
            phi_disclaimer: file.phi_disclaimer,
            is_uploaded: file.is_uploaded,
          };
          documentPaths.push(obj);
        });
      }

      const isReorder =
        singleOrderData?.guid &&
        singleOrderData?.status === 'Pending' &&
        orderTypePath === 'edit';

      let dataSourceArr = [];
      if (!isReorder && datasetList.length > 0) {
        // const dataTypeValues = dataTypeObjValue.split(',');
        // datasetList.map((data) => {
        //   const { data_sources } = data;
        //   const selectedSrc = data_sources.filter((source) =>
        //     dataTypeValues.includes(source.name),
        //   );
        //   dataSourceArr.push(...selectedSrc);
        // });
        selectedDataSourceOption
          ?.filter((dataSource) => dataSource.isChecked)
          .map(({ label, isChecked, value, group, ...rest }) => dataSourceArr.push(rest));
      }

      const order = {
        user: {
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
        },
        patient:
          motivationRequiresPatientData(selectedProviderMotivation) &&
          usersConfig?.configuration?.prognostogram?.motivations?.patient_care
            ? {
                last_name: formState.patientLastName.value,
                birth_date: patientBirthDate ? dateFormatyyyymmdd(patientBirthDate) : '',
              }
            : undefined,
        institute: {
          institute_name: userData.institute.name,
          institute_guid: userData.institute.guid,
        },
        order: {
          initial_clinical_questions:
            questionTypeSelection === 3
              ? formState?.initialQuestionText?.value
              : formState.freeTextInitialQuestionText?.value
              ? formState.freeTextInitialQuestionText?.value
              : formState.questionText.value,
          clinical_questions:
            questionTypeSelection === 3
              ? picoSummaryQuestion
              : formState.questionText.value,
          question_title:
            questionTypeSelection === 3
              ? formState?.question_title?.value
                ? formState?.question_title?.value
                : formState.questionText.value
              : formState.questionText.value,
          population:
            questionTypeSelection === 3
              ? studyFormat?.population
              : formState?.population?.value
              ? formState.population.value
              : '',
          intervention:
            questionTypeSelection === 3
              ? studyFormat?.intervention
              : formState?.intervention?.value
              ? formState.intervention.value
              : '',
          control:
            questionTypeSelection === 3
              ? studyFormat?.control
              : formState?.control?.value
              ? formState.control.value
              : '',
          outcome:
            questionTypeSelection === 3
              ? studyFormat?.outcomes
                ? studyFormat?.outcomes
                : studyFormat?.outcome
                ? studyFormat?.outcome
                : ''
              : formState?.outcome?.value
              ? formState.outcome.value
              : '',
          timeframe: formState?.timeframe?.value,
          picot_status: formState.picotSwitch.value,
          data_source: !isReorder ? dataSourceArr : [singleOrderData.data_source_object],
          sharing_type: sharingValue,
          confidential: isConfidential,
          is_urgent: urgencyType === 'Urgent' ? true : false,
          documents: questionTypeSelection !== 3 ? documentPaths : [],
          phi_disclaimer: documentFileName !== '' ? phiDisclaimer : null,
          previous_order_guid: singleOrderData?.guid,
          general_study_params: generalStudyParams,
          motivations: Object.fromEntries(
            motivations.map(({ category, value }) => [
              category,
              selectedProviderMotivation
                ? selectedProviderMotivation.label === category
                  ? true
                  : false
                : false,
            ]),
          ),
          pico_guid:
            questionTypeSelection === 3
              ? PICOGuid
                ? PICOGuid
                : savedPicoGuid
                ? savedPicoGuid
                : openAiResponse?.guid
                ? openAiResponse?.guid
                : singleOrderData?.ai_search_history?.guid
                ? singleOrderData?.ai_search_history?.guid
                : ''
              : '',
          user_phenotypes: questionTypeSelection === 3 ? phenotypesResponseObject : '',
          product_case_type: questionTypeSelection === 3 ? 'ChatRWD' : 'PG',
          ordered_for: requestOnBehalf
            ? {
                first_name: formState.firstName.value,
                last_name: formState.lastName.value,
                email: formState.newEmail.value,
              }
            : undefined,
        },
      };

      if (isReorder) {
        dispatch(
          modifyPrognostogramOrder({
            params: order,
            orderGuid: singleOrderData?.guid,
            token: token ? token.__raw : '',
            isChatrwd: questionTypeSelection === 3 ? true : false,
            navigateToDashboard:
              questionTypeSelection === 3
                ? orderSuccessCallbackForChatrwd
                : navigateToDashboard,
          }),
        );
      } else {
        dispatch(
          orderPrognostogram({
            params: order,
            csrfToken: Cookies.get('csrftoken'),
            token: token ? token.__raw : '',
            isChatrwd: questionTypeSelection === 3 ? true : false,
            navigateToDashboard:
              questionTypeSelection === 3
                ? orderSuccessCallbackForChatrwd
                : navigateToDashboard,
          }),
        );
      }
    }
  };

  const orderSuccessCallbackForChatrwd = (task) => {
    intervalId.current = setInterval(() => {
      getOrderTaskStatusForChatRwd(task);
    }, 3000);
  };

  const clearTaskInterval = () => {
    clearInterval(intervalId.current);
  };

  const getOrderTaskStatusForChatRwd = async (task) => {
    const token = await getIdTokenClaims();
    dispatch(
      fetchOrderTaskStatus({
        params: { task_ids: task },
        token: token ? token.__raw : '',
        navigateToDashboard: navigateToDashboard,
        errorCallback: clearTaskInterval,
      }),
    );
  };

  const navigateToDashboard = () => {
    clearTaskInterval();
    setOrderSubmitButtonClicked(false);
    dispatch(clearOrderFormData());
    if (!isChatrwdLoaderCloseClicked) {
      history.push(urlConstantsEnum.ORDER_SUBMITTED);
    }
  };

  !!updateProfileError ? setShowLoader(false) : '';

  const onInputChange = (e, name) => {
    if (e.target.value.length <= INPUT_SIZE) {
      setFormState((formState) => ({
        ...formState,
        [name]: {
          ...formState[name],
          value: e.target.value,
          length: e.target.value.length,
        },
      }));
    } else {
      e.preventDefault();
    }
  };

  const setQuestionData = (text) => {
    setAiQuestion(text);
    setFormState((formState) => ({
      ...formState,
      ['questionText']: {
        ...formState['questionText'],
        value: text,
        length: text.length,
      },
    }));
  };

  const setQuestionTitle = (text) => {
    setFormState((formState) => ({
      ...formState,
      ['question_title']: {
        ...formState['question_title'],
        value: text,
        length: text.length,
      },
    }));
  };

  const setInitialClinicalQuestions = (text) => {
    setFormState((formState) => ({
      ...formState,
      ['initialQuestionText']: {
        ...formState['initialQuestionText'],
        value: text,
      },
    }));
  };

  const setShowResult = () => {
    setShowAiResult(!showAiResult);
  };

  const handleSwitchChange = (checked) => {
    mixpanel.track('PICOT switch clicked event', {
      is_picot_option_selected: checked,
    });
    setFormState((formState) => ({
      ...formState,
      picotSwitch: {
        value: checked,
      },
    }));
  };

  const onClickOfBackBtn = () => {
    if (singleOrderData && singleOrderData.guid) {
      dispatch(resetSingleOrderData({}));
    }
    dispatch(clearUploadDocumentsList());
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };

  const handleDocumentUpload = () => {
    mixpanel.track(
      'Document upload option ' + !isDocumentUploadClicked
        ? 'opened'
        : 'closed' + ' event',
    );
    if (!isDocumentUploadClicked) {
      setIsDocumentUploadClicked(true);
    } else {
      setIsDocumentUploadClicked(false);
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      const token = await getIdTokenClaims();
      if (token) {
        dispatch(
          requestGetUserProfile({
            token: getRawToken(token),
          }),
        );
      }
    };

    if (!userData) {
      getProfileData();
    }
  }, []);

  useEffect(() => {
    const getInstituteList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getPhysicianInstituteList({
          params: {
            institute_name: '',
          },
          token: token ? token.__raw : '',
        }),
      );
    };
    getInstituteList();
  }, []);

  useEffect(() => {
    const getDatasetList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getSubscriptionDataset({
          token: token ? token.__raw : '',
          guid: userData?.institute.guid,
        }),
      );
    };
    if (userData) {
      getDatasetList();
    }
  }, [userData]);

  useEffect(() => {
    const getOrderDetails = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getorderByGuid({
          token: getRawToken(token),
          guid: params.guid,
        }),
      );
    };

    if (params && params.guid && !getOrderDetailError) {
      getOrderDetails();
    }
  }, [dispatch, params.guid, getOrderDetailError]);

  useEffect(() => {
    if (!isOrderLoading && singleOrderData && Object.keys(singleOrderData).length > 0) {
      if (singleOrderData?.product_case_type === 'ChatRWD') {
        setQuestionTypeSelection(3);
      } else {
        setQuestionTypeSelection(1);
      }

      if (singleOrderData?.requester !== undefined) {
        if (Object.keys(singleOrderData?.requester).length !== 0) {
          setRequestOnBehalf(singleOrderData?.requester);
        }
      }

      if (singleOrderData?.documents && singleOrderData?.documents?.length > 0) {
        const docArr = singleOrderData.documents.map((doc) => ({
          ...doc,
          is_uploaded: false,
        }));
        dispatch(setUploadedFile(docArr));
      }
      if (
        singleOrderData?.guid &&
        singleOrderData?.status === 'Pending' &&
        orderTypePath === 'edit'
      ) {
        // setDatatypeObjValue(singleOrderData.data_source_object.scope);
        setSelectedDataSourceOption([
          {
            label: singleOrderData?.data_source_object?.scope,
            isChecked: true,
            ...singleOrderData?.data_source_object,
          },
        ]);
      }

      setUrgencyType(
        singleOrderData?.is_urgent && singleOrderData?.is_urgent === true
          ? 'Urgent'
          : 'Not Urgent',
      );
      setInitialStateforEdit(singleOrderData);
      setConfidential(
        singleOrderData?.sharing_type && singleOrderData?.sharing_type === 'Personal'
          ? true
          : false,
      );
      setPatientBirthDate(
        singleOrderData?.patient?.birth_date &&
          dateFormatmmddyyyy(singleOrderData?.patient?.birth_date),
      );
      setSharingValue(singleOrderData?.sharing_type && singleOrderData?.sharing_type);
      // setAiQuestion(singleOrderData?.ai_search_history?.query)
      const presentMotivations =
        singleOrderData &&
        singleOrderData.motivations &&
        singleOrderData.motivations.filter((motivation) => motivation.value);
      if (presentMotivations && presentMotivations.length) {
        const { category } = presentMotivations[0];
        setSelectedProviderMotivation({ label: category });
      }
    }
  }, [isOrderLoading, singleOrderData]);

  // useEffect(() => {
  //   if (
  //     singleOrderData !== undefined &&
  //     singleOrderData?.product_case_type === 'ChatRWD'
  //   ) {
  //     setQuestionTypeSelection(3);
  //   } else {
  //     if(singleOrderData){
  //       setInitialStateforEdit(singleOrderData)
  //       setQuestionTypeSelection(1);
  //     } else {
  //       setQuestionTypeSelection(1);
  //     }
  //   }
  // }, [singleOrderData]);

  const handleDocumentInputUpload = (e) => {
    if (e.target.files[0]) {
      var supportedExtensions = /(\.docx|\.pdf|\.csv|\.xlsx|\.pptx)$/i; //supported extensions
      var maxfilesize = 1024 * 1024 * 25; //Allowing 25MB
      var filesize = e.target.files[0].size;
      if (filesize > maxfilesize) {
        setDocumentError(Strings.documentSizeError);
      } else if (!supportedExtensions.exec(e.target.files[0].name)) {
        setDocumentError(Strings.documentExtensionError);
      } else {
        setDocumentError('');
        setIsShowPhiModal(true);
        setDocumentFile(e.target.files[0]);
        setDocumentFileName(e.target.files[0].name);
      }
    } else {
      setIsShowPhiModal(false);
      resetDocument();
    }
  };

  const resetDocument = () => {
    setDocumentFileName('');
    setDocumentFile(null);
    setDocumentError('');
  };

  const onClickOfDeleteDocument = () => {
    dispatch(resetUploadDocumentData());
    resetDocument();
  };

  const OnClickOfPhiModalClose = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(false);
    if (orderFormData?.previous_order_guid && orderFormData?.documentFileName !== '') {
      setDocumentFileName(orderFormData?.documentFileName);
    } else {
      resetDocument();
    }
  };

  const OnClickOfPhiModalConfirm = () => {
    setIsShowPhiModal(false);
    setPhiDisclaimer(true);
    uploadDocument(true);
  };

  const uploadDocument = async (phiDisclaimerStatus = false) => {
    var data = new FormData();
    data.append('file', documentFile);
    const token = await getIdTokenClaims();
    if (token) {
      dispatch(
        uploadOrderDocument({
          token: getRawToken(token),
          params: data,
          tokenExpiredCallback: redirectToLogin,
          fileDetails: {
            file: documentFile,
            phiDisclaimerStatus,
          },
        }),
      );
    } else {
      redirectToLogin();
    }
  };

  const getDocument = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getOrderDocument({
        token: token ? token.__raw : '',
        guid: orderFormData?.previous_order_guid,
        tokenExpiredCallback: redirectToLogin,
      }),
    );
  };

  const redirectToLogin = () => {
    history.push(urlConstantsEnum.LOGIN);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowTooltip(false);
      mixpanel.track('PICOT tooltip closed event');
    }
    if (reftooltip.current && !reftooltip.current.contains(event.target)) {
      setShowAITooltip(false);
      mixpanel.track('AI tooltip closed event');
    }
    if (refConfidential.current && !refConfidential.current.contains(event.target)) {
      setShowTooltipConfidential(false);
      mixpanel.track('Confidential tooltip closed event');
    }
    if (refMotivation.current && !refMotivation.current.contains(event.target)) {
      setShowMotivationTooltip(false);
      mixpanel.track('Motivation tooltip closed event');
    }
    if (refRequester.current && !refRequester.current.contains(event.target)) {
      setShowRequesterTooltip(false);
      mixpanel.track('Requester tooltip closed event');
    }
    if (refPHI.current && !refPHI.current.contains(event.target)) {
      setShowPHITooltip(false);
      mixpanel.track('Motivation tooltip closed event');
    }
  };

  const onCancelRequest = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleOnCancelRequest = () => {
    clearInterval(intervalId.current);
    dispatch(resetChartrwdErrors());
    dispatch(resetSingleOrderData({}));
    dispatch(resetPicoData());
    dispatch(resetOrderIndexData());
    dispatch(resetUploadDocumentData());
    dispatch(clearUploadDocumentsList());
    if (location?.state?.isFromSearch) {
      history.push({
        pathname:
          orderScopeData?.scopeTabIndex === 1
            ? urlConstantsEnum.LIBRARY_SEARCH_RESULT
            : urlConstantsEnum.SEARCH_RESULT,
        state: {
          searchQuery: location?.state?.searchText,
        },
      });
    } else {
      history.push(
        orderScopeData?.scopeTabIndex === 1
          ? urlConstantsEnum.LIBRARY_DASHBOARD
          : urlConstantsEnum.DASHBOARD,
      );
    }
  };

  const setAdvacedSettingBlock = () => {
    mixpanel.track(
      'Advanced setting option ' + !isButtonClicked ? 'opened' : 'closed' + ' event',
    );
    if (!isButtonClicked) {
      setIsButtonClicked(true);
    } else {
      setIsButtonClicked(false);
    }
  };

  const onClickOfUrgencyButton = (e) => {
    mixpanel.track('Urgency of request options clicked', {
      selected_urgency_value: e.target.value,
    });
    setUrgencyType(e.target.value);
  };

  const addLabelAndValueToDataSources = (data) => {
    return data?.map((entry) => {
      const updatedDataSources = entry.data_sources.map((dataSource) => ({
        ...dataSource,
        label: dataSource.name,
        value: dataSource.name,
      }));

      return {
        ...entry,
        data_sources: updatedDataSources,
      };
    });
  };

  const handleDataSourceOptionChange = (e, option, group) => {
    const { name, checked } = e.target;

    setSelectedDataSourceOption((prevOptions) => {
      const groupExists = prevOptions.some(
        (opt) => opt.group === group && opt.label === name,
      );

      let updatedOptions;
      if (groupExists) {
        updatedOptions = prevOptions.map((opt) =>
          opt.group === group && opt.label === name
            ? { ...opt, isChecked: checked }
            : opt,
        );
      } else {
        updatedOptions = [
          ...prevOptions,
          {
            group,
            label: name,
            isChecked: checked,
            ...option,
          },
        ];
      }

      const allUnchecked = updatedOptions.every((opt) => !opt.isChecked);
      if (allUnchecked) {
        setFormErrorsObject((prevValue) => ({
          ...prevValue,
          dataSourceSelectionError: Strings.dataSourceSelectionError,
        }));
      } else {
        setFormErrorsObject((prevValue) => ({
          ...prevValue,
          dataSourceSelectionError: undefined,
        }));
        if (formErrors) setFormErrors('');
      }

      return updatedOptions;
    });
  };

  const getUrgencyOfRequestList = () => {
    const listItems = urgencyTypeList?.map((source, index) => (
      <div
        className={`radio-input d-flex flex-self-center ${
          index === 0 ? 'mr-0 mb-2' : ''
        }`}
        key={index + '-' + source}
      >
        <label className="d-flex flex-self-center">
          <input
            id={'not-urgent'}
            type="radio"
            name={'urgencyList'}
            value={urgencyTypeList[index]}
            onClick={onClickOfUrgencyButton}
            className="mr-3"
            checked={urgencyType === source ? true : false}
            readOnly
          />
          <div className="d-flex flex-self-center flex-items-center">
            <span>{source} &nbsp;</span> {source === 'Urgent' ? '< ' : '> '}48hrs
          </div>
        </label>
      </div>
    ));
    return (
      <div className="mb-4 urgency-container">
        <div className="heading-text mb-3">Urgency of Request</div>
        <div className="s">{listItems}</div>
      </div>
    );
  };

  const renderDataSourceUI = () => {
    return (
      <div
        className={`data-source-container mb-3 position-relative ${
          singleOrderData?.guid &&
          singleOrderData?.status === 'Pending' &&
          orderTypePath === 'edit'
            ? // orderFormData?.isEditOrder
              'disabled-data'
            : ''
        }`}
      >
        <div className="advanced-setting-title mb-2">Data Sources</div>

        <div className="mb-2 sub-heading-text">
          To learn more about our data sources,{' '}
          <a
            className=""
            href={'mailto:support@atroposhealth.com'}
            id={'email-click-here'}
          >
            click here
          </a>
        </div>

        <DropdownMenu
          type="group"
          groupKey="data_sources"
          options={addLabelAndValueToDataSources(datasetList)}
          onGroupCheckboxCheck={handleDataSourceOptionChange}
          selectedOptions={selectedDataSourceOption}
          placeholder="Select Answer"
          disabled={
            singleOrderData?.guid &&
            singleOrderData?.status === 'Pending' &&
            orderTypePath === 'edit'
          }
          isNoValidate={errorsObject?.dataSourceSelectionError ? true : false}
          noScroll={true}
          showTooltipInfo={true}
        />
        {errorsObject.dataSourceSelectionError && (
          <div className="color-error error-text">
            {errorsObject.dataSourceSelectionError}
          </div>
        )}

        {/* <div ref={dataSourceDivRef} className="">
          <div
            className="position-relative"
            onClick={() => onClickOfDataSource()}
            id={'data-source-selection-dropdown'}
          >
            <div
              className={`data-source ${
                dataTypeObjValue === '' ? 'placeholder-color' : ''
              } 
                ${errorsObject.dataSourceSelectionError && 'input-no-validate'}`}
            >
              {dataTypeObjValue === '' ? 'Select Answer' : dataTypeObjValue}
            </div>
            <ButtonLink
              cssClass="position-absolute"
              buttonAction="Select data source clicked"
              id={'data-source-dropdown-arrow'}
            >
              <HiOutlineChevronDown />
            </ButtonLink>
          </div>
          {errorsObject.dataSourceSelectionError && (
            <div className="color-error error-text">
              {errorsObject.dataSourceSelectionError}
            </div>
          )}
          {isShowDataSetDropDown ? (
            <div className="p-0 d-flex-toggle d-flex flex-justify-center data-source-list mt-1 position-absolute">
              {getDataSourceList()}
            </div>
          ) : (
            ''
          )}
        </div> */}
      </div>
    );
  };

  const handleDateChange = (date) => {
    setFormErrors('');
    setPatientBirthDate(date);
    setPatientDatePickerIsOpen(false);
  };

  const validateBirthdate = (date) => {
    if (date !== '' && date !== null) {
      var settedDate = new Date(date);
      var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      if (settedDate > currentDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onBlurOfDate = (date) => {
    if (date && !validateBirthdate(date)) {
      setShouldShowBirthdayError(true);
      setPatientBirthDate('');
    }
  };

  const onFocusOfDate = () => {
    setShouldShowBirthdayError(false);
  };

  useEffect(() => {
    if (fetchedDocument !== undefined && fetchedDocument !== '') {
      window.open(fetchedDocument);
      dispatch(resetOrderDocument());
    }
  }, [fetchedDocument]);

  // useEffect(() => {
  //   if (singleOrderData?.requester !== undefined) {
  //     if (Object.keys(singleOrderData?.requester).length !== 0) {
  //       setRequestOnBehalf(singleOrderData?.requester);
  //     }
  //   }
  // }, [singleOrderData]);

  const setDataSourceChecked = (value) => {
    if (dataTypeObjValue) {
      var dataSourceValues = dataTypeObjValue.split(',');
      var index = dataSourceValues.indexOf(value);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleDataSourceChange = (e) => {
    var dataSourceValues = dataTypeObjValue !== '' ? dataTypeObjValue.split(',') : [];
    if (datasetList && datasetList.length > 0) {
      datasetList?.map((source) => {
        const { data_sources } = source;
        data_sources.map((dataSources) => {
          if (e.target.value === dataSources.name) {
            if (e.target.checked) {
              dataSourceValues.push(e.target.value);
              setFormErrorsObject((prevValue) => ({
                ...prevValue,
                dataSourceSelectionError: undefined,
              }));
              setFormErrors('');
            } else {
              var index = dataSourceValues.indexOf(e.target.value);
              if (index > -1) {
                dataSourceValues.splice(index, 1);
              }

              if (dataSourceValues.length == 0) {
                setFormErrorsObject((prevValue) => ({
                  ...prevValue,
                  dataSourceSelectionError: Strings.dataSourceSelectionError,
                }));
              }
            }
          }
        });
      });
    }
    mixpanel.track('Data source selected event', {
      idata_source_selected_value: dataSourceValues.join(),
    });
    setDatatypeObjValue(dataSourceValues.join());
  };

  const getDataSourceList = () => {
    if (datasetList && datasetList.length > 0) {
      const listItems = datasetList?.map((source, index) => {
        const { data_sources } = source;
        return (
          <div className="source-group-container" key={index + '-' + source.name}>
            <div className="source-group-name py-2 px-2">{source.name}</div>
            {data_sources?.map((dataSource, index) => (
              <div
                className="d-flex align-items-center my-3 px-2"
                key={index + '-' + dataSource.name}
              >
                <li className="d-flex flex-content-center color-black li-list-specialty dataSource-options source-group-options">
                  <Checkbox
                    buttonName={dataSource.name}
                    onChange={handleDataSourceChange}
                    name={'dataSource'}
                    value={data_sources[index].name}
                    checked={setDataSourceChecked(dataSource.name)}
                    id={'checkbox-data-source-selection'}
                  />
                </li>
                <div className="ml-2 clickable request-section-tooltip">
                  <ImInfo size={14} />
                  <span
                    className={`request-section-tooltiptext datasource-info-top-tooltip 
                    ${
                      data_sources[index].name.length <= 15
                        ? 'datasource-xsm-tooltipText'
                        : data_sources[index].name.length <= 25
                        ? 'datasource-sm-tooltipText'
                        : 'datasource-responsive-tooltip'
                    }`}
                  >
                    {data_sources[index].description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        );
      });
      return <ul className="">{listItems}</ul>;
    }
    return (
      <div className="py-3 px-2 text-left d-block w-100">No Data Sources Available</div>
    );
  };

  useEffect(() => {
    function handler(event) {
      if (!dataSourceDivRef.current?.contains(event.target)) {
        setShowDataSetDropDown(false);
      }
    }
    window.addEventListener('click', handler);
    return () => window.removeEventListener('click', handler);
  }, []);

  useEffect(() => {
    const getSeedMotivations = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getMotivations({
          token: getRawToken(token),
        }),
      );
    };

    getSeedMotivations();
  }, []);

  const getUserRequesters = async () => {
    const token = await getIdTokenClaims();
    dispatch(
      getRequesters({
        token: getRawToken(token),
        guid,
      }),
    );
  };

  useEffect(() => {
    getUserRequesters();
  }, [guid]);

  const onClickOfDataSource = () => {
    if (
      singleOrderData?.guid &&
      singleOrderData?.status === 'Pending' &&
      orderTypePath === 'edit'
    ) {
      setShowDataSetDropDown(false);
    } else {
      mixpanel.track('Data set dropdown clicked event');
      setShowDataSetDropDown(!isShowDataSetDropDown);
    }
  };

  const showCollapseIcon = () => {
    return isShowLeftSection ? 'Hide' : 'Expand';
  };

  const toggleLeftPanel = () => {
    setIsShowLeftSection(!isShowLeftSection);
  };

  const onClickOfQuestionRequestTypeSlection = (index, value) => {
    mixpanel.track(`Order type ${value} selected`, {
      selection_Value: value,
    });
    setQuestionTypeSelection(index);
  };

  useEffect(() => {
    const getUsersConfigData = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        getUsersConfig({
          token: getRawToken(token),
        }),
      );
    };
    getUsersConfigData();
  }, [dispatch, getIdTokenClaims]);

  useEffect(() => {
    dispatch(resetPicoData());
    dispatch(resetPicoOptionsData());
    dispatch(resetPhenotypeData());
    if (isNotificationModalOpen) {
      dispatch(resetOrderNotificationDetails());
      dispatch(handleOrderDetailModal(false));
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetSuggestions());
      dispatchSuggestionAction({ type: 'resetPICOState' });
      if (isNotificationModalOpen) {
        dispatch(resetOrderNotificationDetails());
        dispatch(handleOrderDetailModal(false));
      }
    };
  }, []);

  useEffect(() => {
    if (
      fileDowloadLink !== undefined &&
      fileDowloadLink !== '' &&
      resource === resourceEnum.ARTIFACTS
    ) {
      window.open(fileDowloadLink);
      dispatch(resetDownloadLink());
    }
  }, [fileDowloadLink]);

  const OClickOfLoaderCloseButton = () => {
    clearTaskInterval();
    dispatch(setChatrwdLoaderCloseClicked({ clicked: true }));
    history.push(
      orderScopeData?.scopeTabIndex === 1
        ? urlConstantsEnum.LIBRARY_DASHBOARD
        : urlConstantsEnum.DASHBOARD,
    );
  };
  const handleRemoveDocument = (index) => {
    if (isMaxDocumentUploaded) {
      setIsMaxDocumentUploaded(false);
    }
    dispatch(removeUploadedFile(index));
  };

  const handleMaxDocUploadErr = (e) => {
    if (uploadedFilesList && uploadedFilesList.length == 5) {
      e.preventDefault();
      setIsMaxDocumentUploaded(true);
    }
  };

  const OnClickOfOrderClose = () => {
    dispatch(resetOrderNotificationDetails());
    dispatch(handleOrderDetailModal(false));
  };

  const modifyOrder = (order, edit) => {
    OnClickOfOrderClose();
    history.push(
      `${urlConstantsEnum.REQUEST_PROGNOSTOGRAMSECTION_ONE}/${
        edit === true ? 'edit' : 'reorder'
      }/${order.guid}`,
    );
  };

  const handleClickonOption = (option) => {
    setFormErrors('');
    setFormErrorsObject((prevValue) => ({
      ...prevValue,
      motivation: undefined,
    }));
    setSelectedProviderMotivation(option);
  };

  const handleMotivationClear = (e) => {
    e.stopPropagation();
    setSelectedProviderMotivation({});
    setFormErrorsObject((prevValue) => ({
      ...prevValue,
      motivation: Strings.motivationError,
    }));
  };

  const handleDropdownControlClick = () => {
    setTimeout(() => {
      const dropdownPanel = document.getElementById('dropdown-panel');
      if (dropdownPanel) {
        const rect = dropdownPanel.getBoundingClientRect();
        const isFullyVisible =
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
        if (!isFullyVisible) {
          const targetDiv = document.getElementById('dropdown-panel');
          if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'end' });
          }
        }
      }
    }, 100);
  };

  const handleClickonRequestersOption = (option) => {
    const { first_name, last_name, email } = option?.value;
    setSelectedPreviousRequestor({
      label: 'Previously submitted - ' + first_name + ' ' + last_name,
      first_name,
      last_name,
      email,
      checkedOption: first_name + ' ' + last_name + ' - ' + email,
    });
    handleChange(first_name, 'firstName');
    handleChange(last_name, 'lastName');
    handleEmailChange(email);
  };
  function handleStartOver() {
    setFormState(initialState);
  }

  return isOrderLoading ? (
    isOrderSubmitButtonClicked ? (
      <ChatrwdOrderLoader handleClose={OClickOfLoaderCloseButton} />
    ) : (
      <AuthLoader fullScreen={true} />
    )
  ) : isgettingOrderDetails ? (
    <AuthLoader fullScreen={true} />
  ) : (
    <div className="order-container pb-3 order-single-container">
      <OrderPrognostogramHeader
        onClickOfBackBtn={() => setIsConfirmationModalOpen(true)}
        isSinglePage={true}
        headerText={
          singleOrderData?.guid && orderTypePath === 'edit'
            ? 'Edit Order'
            : singleOrderData?.guid && orderTypePath === 'reorder'
            ? 'Modify and Reorder'
            : 'Order a Prognostogram'
        }
      />
      <CustomModal
        isOpen={isShowPhiModal}
        handleClose={OnClickOfPhiModalClose}
        cssClass="white-overlay"
        modalname="PHI modal"
        overlayCssClass={'phi-modal-overlay'}
      >
        <div className="email-modal p-5 delete-modal phi-modal">
          <div className="profile-sub-header mt-4 mb-2">{Strings.phiModalText1}</div>
          <div className="d-md-flex flex-justify-end">
            <ButtonCustom
              cssClass="plain-btn mt-5 px-4"
              onClick={() => OnClickOfPhiModalClose()}
              buttonAction={'Cancel PHI button clicked'}
              actionLabel={'Cancel document contains PHI'}
              id={'btn-cancel-phi-button'}
            >
              Cancel
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
              onClick={() => OnClickOfPhiModalConfirm()}
              buttonAction={'PHI confirm button clicked'}
              actionLabel={'Confirm document does not have PHI'}
              id={'btn-confirm-phi-button'}
            >
              Confirm
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
      <div className="order-inner-container d-md-flex position-relative">
        {/* <div className="header-title mt-2 d-md-none d-block px-3 py-4"> 
          <div className='d-flex justify-content-between align-items-center'>
            <div>Request Details ss</div>
            <div className={`collapse-btn position-static ${isShowLeftSection ? '' : 'toggle-btn'}`} onClick={() => toggleLeftPanel()}>
              {showCollapseIcon()}
            </div>
          </div>
        </div> */}

        <div
          className={`order-left-section md-position-sticky left-section-wraper ${
            isShowLeftSection ? '' : 'd-none'
          }`}
        >
          <div className="wrapper">
            <div className="header header-container">
              <div className="d-flex justify-content-between align-items-center">
                <div className="header-title">Request Details</div>
                <div
                  className={`collapse-btn position-static ${
                    isShowLeftSection ? '' : 'toggle-btn'
                  }`}
                  onClick={() => toggleLeftPanel()}
                >
                  {showCollapseIcon()}
                </div>
              </div>
            </div>
            <div className="content content-wrapper" id="content-wrapper">
              <div>
                <div className="order-section-container clinical-question-container">
                  <div className="user-details mb-3">
                    <div className="col-md-12 d-md-flex">
                      <div className="col-6">
                        <div className="user-details-label">First Name</div>
                        <div className="user-details-value">{first_name}</div>
                      </div>
                      <div className="col-6">
                        <div className="user-details-label">Last Name</div>
                        <div className="user-details-value">{last_name}</div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="col-12 user-details-wrapper mb-3">
                        <div className="user-details-label">Email</div>
                        <div className="user-details-value">{email}</div>
                      </div>
                      <div className="col-12">
                        <div className="user-details-label">Organization</div>
                        <div className="user-details-value">{institute.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 urgency-container">
                    <div className="advanced-setting-title mb-3">
                      Are you submitting on behalf of somebody else?
                    </div>
                    <div className="d-lg-flex">
                      <div className={`radio-input d-flex flex-self-center`}>
                        <label className="d-flex flex-self-center">
                          <input
                            id={'not-requester'}
                            type="radio"
                            name={'requestOnBehalf'}
                            value={false}
                            className="mr-3"
                            checked={!requestOnBehalf}
                            onChange={() => {
                              const resetRequestFor = () => {
                                setSelectedPreviousRequestor(null);
                                setFormState((formState) => ({
                                  ...formState,
                                  firstName: '',
                                  lastName: '',
                                  newEmail: '',
                                }));
                              };
                              resetRequestFor();
                              setRequestOnBehalf(false);
                              setFormErrorsObject((prev) => ({
                                ...prev,
                                firstName: '',
                                lastName: '',
                                email: '',
                              }));
                            }}
                          />
                          <div className="d-flex flex-self-center flex-items-center">
                            <span>No</span>
                          </div>
                        </label>
                      </div>
                      <div className={`radio-input d-flex flex-self-center`}>
                        <label className="d-flex flex-self-center">
                          <input
                            id={'yes-requester'}
                            type="radio"
                            name={'requestOnBehalf'}
                            value={true}
                            className="mr-3"
                            checked={requestOnBehalf}
                            onChange={() => {
                              setRequestOnBehalf(true);
                              // getUserRequesters();
                            }}
                          />
                          <div className="d-flex flex-self-center flex-items-center">
                            <span>Yes</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {requestOnBehalf && (
                    <div className="mb-4">
                      <div className="advanced-setting-title mb-2 flex items-center">
                        Prognostogram Requester
                        <div className="d-flex">
                          <div className="tooltip-container flex-self-center">
                            <div
                              style={{ lineHeight: '16px' }}
                              className={
                                showRequesterTooltip
                                  ? 'tooltip-box visible'
                                  : 'tooltip-box'
                              }
                              ref={refRequester}
                            >
                              Both yourself and the requester will be returned the
                              Prognostogram and be able to view it in the Portal under
                              your Personal Tab. <span className="tooltip-arrow" />
                            </div>
                            <div
                              className="tooltip-button d-flex"
                              id={'picot-tooltip'}
                              onMouseEnter={() => {
                                setShowRequesterTooltip(true);
                                mixpanel.track('PICOT tooltip opened event');
                              }}
                            >
                              <ImInfo />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        {requesters && requesters.length ? (
                          <>
                            <div className="input-label mb-2">Select Answer</div>
                            <DropdownMenu
                              placeholder="Previously submitted"
                              options={
                                requesters
                                  ? requesters.map((onBehalfOf) => ({
                                      label:
                                        onBehalfOf.first_name +
                                        ' ' +
                                        onBehalfOf.last_name +
                                        ' - ' +
                                        onBehalfOf.email,
                                      value: onBehalfOf,
                                    }))
                                  : []
                              }
                              onClickonOption={handleClickonRequestersOption}
                              selectedOptions={selectedPreviousRequestor}
                              isClearable={
                                selectedPreviousRequestor?.label ? true : false
                              }
                              customPlaceholderString="Previously submitted - "
                              onClearSelectedOption={() => {
                                setSelectedPreviousRequestor({});
                                handleChange('', 'firstName');
                                handleChange('', 'lastName');
                                handleEmailChange('');
                              }}
                            />
                            {/* <Select
                              disabled={!requesters}
                              options={
                                requesters
                                  ? requesters.map((onBehalfOf) => ({
                                      label:
                                        onBehalfOf.first_name +
                                        ' ' +
                                        onBehalfOf.last_name +
                                        ' - ' +
                                        onBehalfOf.email,
                                      value: onBehalfOf,
                                    }))
                                  : []
                              }
                              value={
                                selectedPreviousRequestor
                                  ? {
                                      label:
                                        'Previously submitted - ' +
                                        selectedPreviousRequestor.first_name +
                                        ' ' +
                                        selectedPreviousRequestor.last_name,
                                    }
                                  : null
                              }
                              styles={customStyles}
                              onChange={(selectedLabel) => {
                                if (selectedLabel) {
                                  const { first_name, last_name, _, email } =
                                    selectedLabel.value;
                                  setSelectedPreviousRequestor({
                                    first_name,
                                    last_name,
                                    email,
                                  });
                                  handleChange(first_name, 'firstName');
                                  handleChange(last_name, 'lastName');
                                  handleEmailChange(email);
                                } else {
                                  setSelectedPreviousRequestor(null);
                                  handleChange('', 'firstName');
                                  handleChange('', 'lastName');
                                  handleEmailChange('');
                                }
                              }}
                              placeholder={'Previously submitted'}
                              isClearable={true}
                              id={'select-onboardinng-organization'}
                              noOptionsMessage={({ inputValue: string }) =>
                                'No Previous Requesters'
                              }
                            /> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="d-lg-flex mb-3">
                        <div className="col-lg-6 mr-lg-2">
                          <div className="input-label mb-2">First Name</div>
                          <TextInput
                            isError={
                              !!errorsObject.firstName && !formState.firstName.value
                            }
                            errorText={errorsObject.firstName}
                            disabled={!!selectedPreviousRequestor?.label}
                            value={formState.firstName.value || ''}
                            placeholder="E.g. Filipe"
                            isFloatingLabel={false}
                            onChange={(e) => handleChange(e.target.value, 'firstName')}
                            id={'textInput-onboardinng-first-name'}
                          />
                        </div>
                        <div className="col-lg-6 ml-lg-2">
                          <div className="input-label mb-2">Last Name</div>
                          <TextInput
                            isError={!!errorsObject.lastName && !formState.lastName.value}
                            errorText={errorsObject.lastName}
                            disabled={!!selectedPreviousRequestor?.label}
                            placeholder="E.g  Berger"
                            value={formState.lastName.value || ''}
                            onChange={(e) => handleChange(e.target.value, 'lastName')}
                            isFloatingLabel={false}
                            id={'textInput-onboardinng-last-name'}
                          />
                        </div>
                      </div>
                      <div className="mr-lg-2 mr-0">
                        <div className="col-lg-6 pr-lg-1">
                          <div className="input-label mb-2">Email</div>
                          <TextInput
                            isError={!!errorsObject.email && !formState.newEmail.value}
                            errorText={errorsObject.email}
                            disabled={!!selectedPreviousRequestor?.label}
                            placeholder="Email"
                            value={formState.newEmail.value}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            isFloatingLabel={false}
                            id={'textInput-email'}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mb-4">
                    <div className="advanced-setting-title mb-3">
                      What is the motivation for this request?
                      <div className="d-flex">
                        <div className="tooltip-container flex-self-center">
                          <div
                            style={{ lineHeight: '16px' }}
                            className={
                              showMotivationTooltip
                                ? 'tooltip-box visible'
                                : 'tooltip-box'
                            }
                            ref={refMotivation}
                          >
                            If this Prognostogram is for an eConsult or Direct Patient
                            Care, we will ask to capture the patient’s last name and DOB.
                            <span className="tooltip-arrow" />
                          </div>
                          <div
                            className="tooltip-button d-flex"
                            id={'picot-tooltip'}
                            onMouseEnter={() => {
                              setShowMotivationTooltip(true);
                              mixpanel.track('PICOT tooltip opened event');
                            }}
                          >
                            <ImInfo />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      {/* <Select
                        isDisabled={false}
                        options={motivations?.map((motivation) => ({
                          label: motivation.category,
                        }))}
                        value={selectedProviderMotivation}
                        styles={motivationCustomStyles}
                        onChange={(motivation) => {
                          setFormErrors('');
                          setSelectedProviderMotivation(motivation);
                        }}
                        placeholder={'Select Answer'}
                        isClearable={true}
                        id={'select-onboardinng-organization'}
                        noOptionsMessage={({ inputValue: string }) =>
                          'Please select a valid provider motivation'
                        }
                      /> */}
                      <DropdownMenu
                        options={motivations?.map((motivation) => ({
                          label: motivation.category,
                          value: motivation.category,
                        }))}
                        onClickonOption={handleClickonOption}
                        selectedOptions={selectedProviderMotivation}
                        isClearable={selectedProviderMotivation?.label ? true : false}
                        placeholder="Select Answer"
                        onClearSelectedOption={handleMotivationClear}
                        isNoValidate={errorsObject?.motivation ? true : false}
                        onDropdownControlClick={handleDropdownControlClick}
                      />
                      {errorsObject.motivation ? (
                        <div
                          style={{ color: '#DB0028', fontSize: '11px', marginTop: '3px' }}
                        >
                          {errorsObject.motivation}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {selectedProviderMotivation &&
                    motivationRequiresPatientData(selectedProviderMotivation) &&
                    usersConfig?.configuration?.prognostogram?.motivations
                      ?.patient_care && (
                      <div className="mb-4">
                        <div className="advanced-setting-title mb-2">
                          Patient’s Information
                          <div className="d-flex">
                            <div className="tooltip-container flex-self-center">
                              <div
                                style={{ lineHeight: '16px' }}
                                className={
                                  showPHIToolTip ? 'tooltip-box visible' : 'tooltip-box'
                                }
                                ref={refPHI}
                              >
                                We take your patient’s Protected Health Information (PHI)
                                seriously and have implemented infrastructure to comply
                                with HIPAA and general privacy practices. See more on our
                                policy{' '}
                                <a href="https://www.atroposhealth.com/HIPAA">here</a>
                                <span className="tooltip-arrow" />
                              </div>
                              <div
                                className="tooltip-button d-flex"
                                id={'picot-tooltip'}
                                onMouseEnter={() => {
                                  setShowPHITooltip(true);
                                  mixpanel.track('PICOT tooltip opened event');
                                }}
                              >
                                <ImInfo />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 pr-1 mb-4">
                          <div className="input-label mb-2">Last Name</div>
                          <TextInput
                            isError={
                              errorsObject.patientLastName &&
                              !formState.patientLastName.value
                            }
                            errorText={errorsObject.patientLastName}
                            value={formState.patientLastName.value}
                            placeholder="Last Name"
                            isFloatingLabel={false}
                            onChange={(e) =>
                              handleChange(e.target.value, 'patientLastName')
                            }
                            id={'textInput-first-name'}
                          />
                        </div>

                        <div className="input-label mb-1">Date of Birth</div>
                        <div className="col-md-6 pr-1 mb-4">
                          <TextInput
                            isError={
                              errorsObject.patientBirthDate &&
                              !isValidDate(new Date(patientBirthDate))
                            }
                            errorText={errorsObject.patientBirthDate}
                            value={patientBirthDate}
                            placeholder="MM/DD/YYYY"
                            isFloatingLabel={false}
                            className={`${ShouldShowBirthdayError ? 'border-error' : ''}`}
                            onFocus={() => {
                              setPatientDatePickerIsOpen(true);
                            }}
                            onChange={(e) => {
                              setPatientDatePickerIsOpen(false);
                              let value = e.target.value;
                              const numberOfSlashes = value.split('/').length - 1;

                              const needsSlashAfterDay =
                                value.replace(/[^0-9]/g, '').length == 2 &&
                                numberOfSlashes < 1;
                              const needSlashAfterMonth =
                                numberOfSlashes == 1 &&
                                value.split('/')[1].replace(/[^0-9]/g, '').length == 2;
                              if (
                                (needsSlashAfterDay || needSlashAfterMonth) &&
                                patientBirthDate.slice(-1) != '/'
                              ) {
                                value += '/';
                              }
                              handleDateChange(value);
                            }}
                            id={'datepicker-birthdate'}
                          />
                          {patientDatePickerIsOpen && (
                            <DatePicker
                              inline
                              className={`${
                                ShouldShowBirthdayError ? 'border-error' : ''
                              }`}
                              onChange={(date) => {
                                handleDateChange(date.toLocaleDateString('en-US'));
                              }}
                              id={'datepicker-birthdate-picker'}
                            />
                          )}
                        </div>

                        {ShouldShowBirthdayError ? (
                          <div className="color-error error-text">
                            {orderFormErrorStr.birthDate}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    )}

                  {renderDataSourceUI()}

                  {questionTypeSelection !== 3 && (
                    <div>
                      <div className="advaced-settings-div mb-4">
                        <div
                          className="d-flex cursor-pointer"
                          onClick={() => handleDocumentUpload()}
                          id={'document-upload-option'}
                        >
                          <div className="advanced-setting-title">Document Upload</div>
                          <div
                            className={
                              !isDocumentUploadClicked
                                ? 'button-position right-arrow'
                                : 'button-position right-arrow rotate-button'
                            }
                          >
                            <ButtonLink
                              buttonAction="Document Upload section clicked"
                              id={'document-upload-icon'}
                            >
                              <HiOutlineChevronRight />
                            </ButtonLink>
                          </div>
                        </div>
                        {isDocumentUploadClicked ? (
                          <>
                            {!isUploadingDocument ? (
                              <>
                                <div
                                  className={`advanced-setting-body mt-2 ${
                                    !isAuthenticated ? 'disbaled-document-upload' : ''
                                  }`}
                                >
                                  {uploadedFilesList && uploadedFilesList.length > 0 ? (
                                    <div className="chip-container my-3">
                                      {uploadedFilesList &&
                                        uploadedFilesList.length > 0 &&
                                        uploadedFilesList.map((doc, index) => (
                                          <div
                                            className="chip"
                                            key={`${doc.key_path + index}`}
                                          >
                                            <span className="chip-label">
                                              {doc.file_name}
                                            </span>
                                            <AiFillDelete
                                              onClick={() => handleRemoveDocument(index)}
                                              className="chip-icon"
                                            />
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <div className="no-file mt-4">No file selected</div>
                                  )}
                                  {documentError !== '' ||
                                  getDocumentUploadError !== undefined ? (
                                    <div className="document-format-text my-3 document-error d-flex flex-items-center">
                                      <MdError />
                                      <div className="ml-2">
                                        {documentError !== ''
                                          ? documentError
                                          : getDocumentUploadError}
                                      </div>
                                    </div>
                                  ) : isMaxDocumentUploaded ? (
                                    <div className="document-format-text my-3 document-error d-flex flex-items-center">
                                      <MdError size={50} />
                                      <div className="ml-2">
                                        You have reached the upload limit of 5 documents.
                                        Please contact{' '}
                                        <a
                                          className="text-primary"
                                          href="mailto:consult@atroposhealth.com"
                                        >
                                          consult@atroposhealth.com
                                        </a>{' '}
                                        if you need to provide further documentation.
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="document-format-text my-3">
                                      {Strings.documentExtensionNote}
                                    </div>
                                  )}
                                  {/* {documentFileName !== '' &&
                                !orderFormData?.previous_order_guid ? (
                                  <>
                                    <ButtonCustom
                                      disabled={!isAuthenticated}
                                      cssClass="btn-primary"
                                      onClick={() => onClickOfDeleteDocument()}
                                      buttonAction={'Delete document button clicked'}
                                      actionLabel={'Deleted selected document'}
                                      id={'btn-delete-document-button'}
                                    >
                                      Delete
                                    </ButtonCustom>
                                  </>
                                ) :  */}

                                  <>
                                    {isAuthenticated ? (
                                      <input
                                        disabled={!isAuthenticated}
                                        type="file"
                                        name="document"
                                        id="document-upload"
                                        className="inputfile d-none"
                                        accept=".docx,.pdf,.csv,.xlsx,.pptx"
                                        value={filePath}
                                        onChange={(e) => {
                                          handleDocumentInputUpload(e);
                                        }}
                                        onClick={(e) => {
                                          handleMaxDocUploadErr(e);
                                          setFilePath('');
                                        }}
                                      />
                                    ) : (
                                      ''
                                    )}
                                    <label
                                      htmlFor="document-upload"
                                      className="inputfile-label"
                                    >
                                      Choose
                                    </label>
                                  </>
                                </div>
                                {!isAuthenticated ? (
                                  <div className="document-disabled-text">
                                    To upload a document, please login or create an
                                    account{' '}
                                    <ButtonCustom
                                      onClick={() => OnClickOfLogin()}
                                      cssClass="mt-2 login-btn"
                                      buttonAction="Sign in button clicked"
                                      id={'btn-login-button'}
                                    >
                                      here
                                    </ButtonCustom>
                                    .
                                  </div>
                                ) : (
                                  ''
                                )}
                              </>
                            ) : (
                              <div className="loader-btn-view">
                                <AuthLoader fullScreen={false} />
                              </div>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  )}

                  <div>
                    <div className="advaced-settings-div">
                      <div
                        className="d-flex cursor-pointer"
                        onClick={() => setAdvacedSettingBlock()}
                        id={'advanced-setting-optionn'}
                      >
                        <div className="advanced-setting-title">Other Settings</div>
                        <div
                          className={
                            !isButtonClicked
                              ? 'button-position right-arrow'
                              : 'button-position right-arrow rotate-button'
                          }
                        >
                          <ButtonLink
                            buttonAction="Advanced setting clicked"
                            id={'advaced-setting-icon'}
                          >
                            <HiOutlineChevronRight />
                          </ButtonLink>
                        </div>
                      </div>
                      {isButtonClicked ? (
                        <div className="advanced-setting-body mt-2">
                          {getUrgencyOfRequestList()}
                          <div className="display-advaced-settings-block mt-4 mb-md-8">
                            <div className="heading-text mt-3 mb-2">Sharing</div>
                            <div className="button-container d-md-flex mb-md-10 ">
                              <div
                                className={`toolTip-div pr-4 d-flex align-items-center`}
                                key={'index'}
                              >
                                <Checkbox
                                  buttonName={'Confidential'}
                                  onChange={handleCheck}
                                  name={'sharing-1'}
                                  value={isConfidential}
                                  checked={isConfidential ? true : false}
                                  id={'checkbox-confidential-sharing-setting'}
                                />
                                <div
                                  className="request-section-tooltip"
                                  onMouseOver={() => {
                                    mixpanel.track('Confidential tooltip open event');
                                  }}
                                  onMouseOut={() => {
                                    mixpanel.track('Confidential tooltip closed event');
                                  }}
                                >
                                  <span className="ml-1 position-relative top-1">
                                    <ImInfo size={14} />
                                  </span>
                                  <span className="request-section-tooltiptext support-info-top-tooltip">
                                    By checking this box, you are choosing to keep the
                                    question and resulting Prognostogram confidential for
                                    30 days. After 30 days, Atropos may share the
                                    resulting Prognostogram with authorized users based on
                                    your organization's preferences. Please contact us at{' '}
                                    <a
                                      className="text-white"
                                      href="mailto:support@atroposhealth.com"
                                    >
                                      support@atroposhealth.com
                                    </a>{' '}
                                    if you have any questions.
                                  </span>
                                </div>
                                {/* <div
                                className="tooltip-container container-tooltip-box"
                                key={'index'}
                              >
                                <div
                                  className={
                                    showTooltipConfidential
                                      ? 'tooltip-box visible set-div-width'
                                      : 'tooltip-box'
                                  }
                                  ref={refConfidential}
                                >
                                  <div
                                    className="mx-1 my-1"
                                    onClick={() => {
                                      setShowTooltipConfidential(false);
                                      mixpanel.track('Confidential tooltip closed event');
                                    }}
                                  >
                                    By checking this box, you are choosing to keep the
                                    question and resulting Prognostogram confidential for 30
                                    days. After 30 days, Atropos may share the resulting
                                    Prognostogram with authorized users based on your
                                    organization's preferences. Please contact us at{' '}
                                    <a className="" href="mailto:support@atroposhealth.com">
                                      support@atroposhealth.com
                                    </a>{' '}
                                    if you have any questions.
                                  </div>
                                  <span className="tooltip-arrow" />
                                </div>
                                <div
                                  className="tooltip-button d-md-flex my-2"
                                  onMouseEnter={() => {
                                    setShowTooltipConfidential(true);
                                    mixpanel.track('Confidential tooltip opened event');
                                  }}
                                  onClick={() => {
                                    setShowTooltipConfidential(!showTooltipConfidential);
                                    mixpanel.track(
                                      'Confidential tooltip ' + showTooltipConfidential
                                        ? 'opened'
                                        : 'closed' + ' event',
                                    );
                                  }}
                                >
                                  <ImInfo />
                                </div>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`order-right-section p-md-6 p-3 ${isShowLeftSection ? '' : 'w-100'}`}
        >
          {!isShowLeftSection && (
            <div
              className={`collapse-btn position-static ${
                isShowLeftSection ? '' : 'toggle-btn'
              }`}
              onClick={() => toggleLeftPanel()}
            >
              {showCollapseIcon()}
            </div>
          )}
          <div className="order-section-container clinical-question-container mb-3 pb-5">
            <div className="d-lg-flex flex-justify-between mb-3 position-relative">
              <div className="d-md-flex flex-self-center position-relative">
                <span className="question-label">Enter your question below</span>
              </div>

              <div className="d-md-flex flex-self-start">
                <RadioButton
                  buttonName={'Free Text'}
                  onClick={(e) => onClickOfQuestionRequestTypeSlection(1, 'Free Text')}
                  name={'questionTypeSelection'}
                  value={1}
                  checked={questionTypeSelection === 1 ? true : false}
                  id={'btn-free-text'}
                  classNameValue={`${
                    questionTypeSelection === 1 ? 'radio-active ' : ''
                  }order-radio-btn my-2 order-radio-btn-1`}
                />

                <div
                  className={`${
                    questionTypeSelection === 2 ? 'radio-active ' : ''
                  }position-relative tooltip-btn my-2 order-radio-btn-2`}
                >
                  <RadioButton
                    buttonName={'PICOT '}
                    onClick={(e) => onClickOfQuestionRequestTypeSlection(2, 'PICOT')}
                    name={'questionTypeSelection'}
                    value={2}
                    checked={questionTypeSelection === 2 ? true : false}
                    id={'btn-picot-selection'}
                    classNameValue={`${
                      questionTypeSelection === 2 ? 'radio-active ' : ''
                    }order-radio-btn`}
                  />
                  <div className="tooltip-container flex-self-center">
                    <div
                      className={showTooltip ? 'tooltip-box visible' : 'tooltip-box'}
                      ref={ref}
                    >
                      <span className="font-bold">P</span>opulation,{' '}
                      <span className="font-bold">I</span>ntervention,{' '}
                      <span className="font-bold">C</span>ontrol,
                      <span className="font-bold">O</span>utcome,{' '}
                      <span className="font-bold">T</span>imeframe
                      <div
                        className="mt-2"
                        id={'picot-tooltip-option'}
                        onClick={() => {
                          setShowTooltip(false);
                          mixpanel.track('PICOT tooltip closed event');
                        }}
                      >
                        For more information on how to ask a well structured PICOT
                        question,{' '}
                        <a
                          href={PICOT_QUESTION_LINK}
                          target="_blank"
                          rel="noreferrer"
                          id={'picot-question-link'}
                        >
                          click here
                        </a>
                      </div>
                      <span className="tooltip-arrow" />
                    </div>
                    <div
                      className="tooltip-button d-flex"
                      id={'picot-tooltip'}
                      onMouseEnter={() => {
                        setShowTooltip(true);
                        mixpanel.track('PICOT tooltip opened event');
                      }}
                    >
                      <ImInfo />
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    questionTypeSelection === 3 ? 'radio-active ' : ''
                  }position-relative tooltip-btn my-2 order-radio-btn-3 ${
                    !usersConfig?.configuration?.prognostogram?.ask_pico_with_ai
                      ? 'radio-btn-disabled'
                      : ''
                  }`}
                >
                  <RadioButton
                    buttonName={'ChatRWD'}
                    onClick={(e) => onClickOfQuestionRequestTypeSlection(3, 'ChatRWD')}
                    name={'questionTypeSelection'}
                    value={3}
                    disabled={
                      !usersConfig?.configuration?.prognostogram?.ask_pico_with_ai ||
                      orderTypePath === 'edit'
                    }
                    checked={questionTypeSelection === 3 ? true : false}
                    id={'btn-ask-with-ai'}
                    classNameValue={`order-radio-btn ${
                      questionTypeSelection === 3 ? 'radio-active' : ''
                    } ${
                      !usersConfig?.configuration?.prognostogram?.ask_pico_with_ai ||
                      orderTypePath === 'edit'
                        ? 'disabled'
                        : ''
                    }`}
                  />
                  <div className="tooltip-container flex-self-center">
                    <div
                      className={showAITooltip ? 'tooltip-box visible' : 'tooltip-box'}
                      ref={reftooltip}
                    >
                      <div
                        className="mt-0"
                        id={'ai-tooltip-option'}
                        onClick={() => {
                          setShowAITooltip(false);
                          mixpanel.track('AI tooltip closed event');
                        }}
                      >
                        This feature is currently in beta and only available to certain
                        users. For more information contact us at{' '}
                        <a
                          href={'mailto:support@atroposhealth.com'}
                          id={'AI-question-link'}
                        >
                          support@atroposhealth.com.
                        </a>
                      </div>
                      <span className="tooltip-arrow" />
                    </div>
                    <div
                      className="tooltip-button d-flex"
                      id={'AI-tooltip'}
                      onMouseEnter={() => {
                        setShowAITooltip(true);
                        mixpanel.track('AI tooltip opened event');
                      }}
                    >
                      <ImInfo />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="study-protocol-text mb-3 mt-4">
              {questionTypeSelection === 3 ? (
                <>
                  ChatRWD only currently supports cohort studies (link to Atropos study
                  methods{' '}
                  <a
                    href="https://docs.atroposhealth.com/article/9-methods-overview"
                    target="_blank"
                  >
                    here
                  </a>
                  ). Please submit your cohort study question below.
                </>
              ) : (
                'If you have a study protocol that you would like us to emulate, please include it using the Document Upload feature in the left bar.'
              )}
            </div>
            {questionTypeSelection === 2 ? (
              <div className="picot-container">
                <div className="picot-lable mb-2">Question title</div>
                <TextArea
                  value={formState.questionText.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'questionText')}
                  id={'textarea-question-text'}
                />
                <div className="text-count">
                  {formState.questionText.length}/{INPUT_SIZE}
                </div>
                <div className="picot-lable mb-2">Population</div>
                <TextArea
                  value={formState.population.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'population')}
                  id={'textarea-population'}
                />
                <div className="text-count">
                  {formState.population.length}/{INPUT_SIZE}
                </div>
                <div className="picot-lable mb-2">Intervention</div>
                <TextArea
                  value={formState.intervention.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'intervention')}
                  id={'textarea-intervention'}
                />
                <div className="text-count">
                  {formState.intervention.length}/{INPUT_SIZE}
                </div>
                <div className="picot-lable mb-2">Control</div>
                <TextArea
                  value={formState.control.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'control')}
                  id={'textarea-control'}
                />
                <div className="text-count">
                  {formState.control.length}/{INPUT_SIZE}
                </div>
                <div className="picot-lable mb-2">Outcome</div>
                <TextArea
                  value={formState.outcome.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'outcome')}
                  id={'textarea-outcome'}
                />
                <div className="text-count">
                  {formState.outcome.length}/{INPUT_SIZE}
                </div>
                <div className="picot-lable mb-2">Timeframe</div>
                <TextArea
                  value={formState.timeframe.value}
                  rows={2}
                  onChange={(e) => onInputChange(e, 'timeframe')}
                  id={'textarea-timeframe'}
                />
                <div className="text-count">
                  {formState.timeframe.length}/{INPUT_SIZE}
                </div>
              </div>
            ) : questionTypeSelection === 1 ? (
              <div>
                <TextArea
                  placeholder="Write your clinical question here.."
                  value={formState.questionText.value}
                  rows={14}
                  onChange={(e) => onInputChange(e, 'questionText')}
                  id={'textarea-clinical-question'}
                />
                <div className="text-count">
                  {formState.questionText.length}/{INPUT_SIZE}
                </div>
              </div>
            ) : (
              <AIWorkflow
                previousOrderGuid={singleOrderData?.guid}
                setQuestionData={setQuestionData}
                setQuestionTitle={setQuestionTitle}
                aiQuestion={aiQuestion}
                setShowResult={setShowResult}
                showAiResult={showAiResult}
                setPhenotypeResponse={setPhenotypeResponse}
                setPhenotypesResponseObject={setPhenotypesResponseObject}
                phenotypesResponseObject={phenotypesResponseObject}
                setActiveButton={setActiveButton}
                setSecondActiveButton={setSecondActiveButton}
                secondActiveButton={secondActiveButton}
                setPhenotypeTableResponseText={setPhenotypeTableResponseText}
                phenotypeTableResponseText={phenotypeTableResponseText}
                activeButton={activeButton}
                setIsManuallyEditPicoSelected={setIsManuallyEditPicoSelected}
                isManuallyEditPicoSelected={isManuallyEditPicoSelected}
                setGeneralStudyFormParams={setGeneralStudyParams}
                errorsObject={errorsObject}
                dispatchSuggestionAction={dispatchSuggestionAction}
                suggestionsState={suggestionsState}
                initialClinicalQuestions={formState?.initialQuestionText?.value}
                setInitialClinicalQuestions={setInitialClinicalQuestions}
                formState={formState}
                isQuestionClicked={isQuestionClicked}
                setIsQuestionClicked={setIsQuestionClicked}
                onStartOver={handleStartOver}
              />
            )}
            <div className="d-md-flex flex-md-justify-between">
              <ButtonCustom
                disabled={
                  (questionTypeSelection === 3 && !isPhenotypeResponse) ||
                  !formState.questionText.value ||
                  (isUploadingDocument && documentFileName !== '')
                }
                cssClass={`gradient-btn send-request-btn mt-md-0 mt-3 ${
                  questionTypeSelection === 3 && !isPhenotypeResponse
                    ? 'btn-disabled'
                    : !formState.questionText.value ||
                      (isUploadingDocument && documentFileName !== '')
                    ? 'btn-disabled'
                    : ''
                }`}
                onClick={submitOrder}
                buttonAction={`Submit order button clicked, order type - ${
                  questionTypeSelection === 3 ? 'ChatRWD' : 'PG'
                }`}
                actionLabel={'New PG order is submitted'}
                id={'btn-submit-order-button'}
              >
                Confirm and Send Request
              </ButtonCustom>
              <ButtonCustom
                cssClass="cancel-btn mt-md-0 mt-3"
                onClick={onCancelRequest}
                id={'btn-cancel-order-button'}
                buttonAction={
                  singleOrderData?.guid && orderTypePath === 'edit'
                    ? 'Discard Changes edit order clicked'
                    : 'Cancel new PG order clicked'
                }
                actionLabel={
                  singleOrderData?.guid && orderTypePath === 'edit'
                    ? 'Edit order changes discarded'
                    : 'New PG order canceled'
                }
              >
                {singleOrderData?.guid && orderTypePath === 'edit'
                  ? 'Discard Changes'
                  : 'Cancel Request'}
              </ButtonCustom>
            </div>

            {hasErrors && (
              <ul className="ah-alert ah-alert-error list-bullet">
                {Object.entries(errorsObject).map(
                  ([key, value]) =>
                    value && (
                      <li
                        key={`${key}-${value}`}
                        className="color-error txt size-13 list-spacing"
                      >
                        {value}
                      </li>
                    ),
                )}
              </ul>
            )}
            {!!orderPrognostogramError || !!orderTaskStatusError ? (
              <div className="color-error error-text">
                {orderPrognostogramError ? orderPrognostogramError : orderTaskStatusError}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <CustomModal
        isOpen={getOrderDetailError && true}
        handleClose={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
        cssClass="white-overlay"
        modalname="Order modal"
        overlayCssClass={'order-modal-overlay'}
      >
        <div className={`email-modal p-3 order-modal`}>
          <div className="d-flex error-container my-4">
            <div className="mr-3 flex-self-center">
              <RiErrorWarningFill className="d-block" />
            </div>
            <div className="flex-self-center">
              Oops! It looks like you do not have access to that Prognostogram. If you
              think this is an error, please reach out to our team at{' '}
              <a href="mailto:support@atroposhealth.com">Support@atroposhealth.com</a>
            </div>
          </div>
          <ButtonCustom
            cssClass="close-btn color-white"
            onClick={() => {
              history.push(urlConstantsEnum.LIBRARY_DASHBOARD);
              dispatch(getorderByGuidError({ error: undefined }));
            }}
            buttonAction={'Modal closed button clicked'}
            id={'btn-modal-closed-button'}
          >
            <VscChromeClose />
          </ButtonCustom>
        </div>
      </CustomModal>

      <CustomModal
        isOpen={isConfirmationModalOpen}
        cssClass="white-overlay"
        modalname="PHI modal"
        overlayCssClass={'phi-modal-overlay'}
        handleClose={() => {}}
      >
        <div className="email-modal p-5 delete-modal cancel-request-confirmation-modal">
          <div className="profile-sub-header mt-4 mb-2">
            {Strings.cancelOrderRequestModalTitle}
          </div>
          <div className="cancel-sub-header mt-4">
            {Strings.cancelOrderRequestModalDescription}
          </div>
          <div className="d-md-flex flex-justify-end">
            <ButtonCustom
              cssClass="plain-btn mt-5 px-4"
              onClick={() => setIsConfirmationModalOpen(false)}
              buttonAction={'continue chatting button clicked'}
              actionLabel={'continue chatting'}
              id={'btn-continue-chatting-button'}
            >
              {Strings.continueChattingModalButton}
            </ButtonCustom>
            <ButtonCustom
              cssClass="btn-primary mt-2 mt-md-5 px-4 ml-md-3"
              onClick={() => handleOnCancelRequest()}
              buttonAction={'Cancel Request button clicked'}
              actionLabel={'Cancel Request'}
              id={'btn-cancel-request-button'}
            >
              {Strings.CancelRequestButton}
            </ButtonCustom>
          </div>
        </div>
      </CustomModal>
      {/* <NotificationDetailModal 
        loading={orderNotificationDetailsLoading}
        singleOrderData={orderNotificationDetails} 
        isNotificationModalOpen={isNotificationModalOpen}
        error={orderNotificationDetailsError} 
        questionData={questionData}
        pdfFile={pdfFile}
      /> */}
      {/* <OrderdetailModal
        isOpen={isNotificationModalOpen}
        handleClose={() => OnClickOfOrderClose()}
        isLoading={orderNotificationDetailsLoading}
        data={orderNotificationDetails}
        error={orderNotificationDetailsError}
        questionData={questionData}
        pdfFile={pdfFile}
        onModify={() => modifyOrder(orderNotificationDetails, false)}
        onEdit={() => modifyOrder(orderNotificationDetails, true)}
        OnClickOfOrderClose={() => OnClickOfOrderClose(singleOrderData?.is_owner)}
      /> */}
    </div>
  );
};

export default SinglePageOrderRequest;
