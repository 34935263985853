import React from 'react';
import { useOrdersPagedata } from '../OrderPageContext';
import { FreeTextOrder } from '../freetext';
import { PicotOrder } from '../picot';
import { ChartRwdOrder } from '../chartrwd';
import Header from './Header';

function OrderSwitcher({
  isSidebarHidden,
  toggleSidebar,
  onOrderSuccessCallbackForChatrwd,
}) {
  const {
    ordersPageData: { questionTypeSelection, errorsObject },
  } = useOrdersPagedata();
  const hasErrors = Object.values(errorsObject).some((value) => value !== '');

  const renderOrderComponent = () => {
    switch (questionTypeSelection) {
      case 1:
        return <FreeTextOrder />;
      case 2:
        return <PicotOrder />;
      default:
        return (
          <ChartRwdOrder
            onOrderSuccessCallbackForChatrwd={onOrderSuccessCallbackForChatrwd}
          />
        );
    }
  };

  return (
    <div className={`order-right-section p-md-6 p-3 ${isSidebarHidden ? 'w-100' : ''}`}>
      {isSidebarHidden && (
        <div class="collapse-btn position-static toggle-btn" onClick={toggleSidebar}>
          Expand
        </div>
      )}
      <div className="order-section-container clinical-question-container mb-3 pb-5">
        <Header />
        {renderOrderComponent()}

        {hasErrors && (
          <ul className="ah-alert ah-alert-error list-bullet order-page-error-container">
            {Object.entries(errorsObject).map(
              ([key, value]) =>
                value && (
                  <li
                    key={`${key}-${value}`}
                    className="color-error txt size-13 list-spacing"
                  >
                    {value}
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
}

export default OrderSwitcher;
