import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchQuickSightDashboards,
  fetchDashboardByIdRequest,
} from 'redux/modules/quicksight/action';
import { getRawToken } from 'utils/validations';
import Select from 'react-select';
import AuthLoader from 'components/AuthLoader';
import ErrorBoundaryModal from 'components/ErrorBoundaryModal';
import LogOutUtil from 'utils/LogOutUtil';

const default_dashboard_id = '845cfb1e-cb26-4bad-ba69-5433942b8f64';
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#0D3F3C'
      : state.isFocused
      ? 'rgb(216, 238, 238)'
      : 'white',
    color: state.isSelected ? 'white' : 'black',
    padding: 10,
  }),
};

function QuickSightDashboard() {
  const { getIdTokenClaims } = useAuth0();
  const dispatch = useDispatch();
  const { logOutUser } = LogOutUtil();
  const {
    dashboards,
    isLoadingDashboards,
    isLoadingDashboardById,
    embeddedDashboardUrl,
    error,
  } = useSelector(({ quicksight }) => quicksight);
  const [selectedOption, setSelectedOption] = useState(null);
  const option =
    selectedOption ||
    dashboards.find((dashboard) => dashboard.value === default_dashboard_id);

  useEffect(() => {
    const getQuicksightDashboardsList = async () => {
      const token = await getIdTokenClaims();
      dispatch(
        fetchQuickSightDashboards({
          token: getRawToken(token),
        }),
      );
    };
    getQuicksightDashboardsList();
  }, [dispatch]);

  useEffect(() => {
    if (dashboards && dashboards.length > 0 && option) {
      const getQuicksightDashboardsById = async () => {
        const token = await getIdTokenClaims();
        dispatch(
          fetchDashboardByIdRequest({
            token: getRawToken(token),
            dashboardId: option.value,
          }),
        );
      };
      getQuicksightDashboardsById();
    }
  }, [dashboards, option, dispatch]);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  const resetError = () => {
    logOutUser();
  };

  if (isLoadingDashboards) {
    return <AuthLoader fullScreen={false} />;
  }

  if (error) {
    return (
      <ErrorBoundaryModal error={{ message: error }} resetErrorBoundary={resetError} />
    );
  }

  return (
    <div className="topnav">
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <Select
            value={option}
            onChange={handleChange}
            options={dashboards}
            isSearchable={false}
            isDisabled={isLoadingDashboardById}
            styles={customStyles}
          />
        </div>
      </div>
      <div>
        {isLoadingDashboardById ? (
          <AuthLoader fullScreen={false} />
        ) : (
          <div className="iframe-embed-wrapper iframe-embed-responsive-16by9">
            <iframe src={embeddedDashboardUrl} className="iframe-embed"></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuickSightDashboard;
