import {
  FETCH_QUICKSIGHT_DASHBOARDS_START,
  FETCH_QUICKSIGHT_DASHBOARDS_COMPLETE,
  FETCH_QUICKSIGHT_DASHBOARDS_FAILURE,
  FETCH_DASHBOARD_BY_ID_START,
  FETCH_DASHBOARD_BY_ID_SUCCESS
} from "./types";

let initialState = {
  isLoadingDashboards: false,
  dashboards: [],
  error: '',
  isLoadingDashboardById:false,
  embeddedDashboardUrl:null
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case FETCH_QUICKSIGHT_DASHBOARDS_START:
    return {
      ...state,
      isLoadingDashboards: true,
    };
    case FETCH_QUICKSIGHT_DASHBOARDS_COMPLETE:
      let data = []
      if(payload) {
        Object.entries(payload).map(([key,value]) => {
          const obj = {
            'value': key,
            'label':value.name,
            ...value
          }
          data.push(obj)
        })
      }
    return {
      ...state,
      isLoadingDashboards: false,
      dashboards: data,
      error: ''
    };
    case FETCH_QUICKSIGHT_DASHBOARDS_FAILURE:
    return {
      ...state,
      isLoadingDashboards: false,
      dashboards: [],
      error: payload
    };
    case FETCH_DASHBOARD_BY_ID_START:
    return {
      ...state,
      embeddedDashboardUrl:null,
      isLoadingDashboardById: true,
    };
    case FETCH_DASHBOARD_BY_ID_SUCCESS:
    return {
      ...state,
      isLoadingDashboardById: false,
      embeddedDashboardUrl: payload,
      error: ''
    };
    case FETCH_QUICKSIGHT_DASHBOARDS_FAILURE:
    return {
      ...state,
      isLoadingDashboardById: false,
      embeddedDashboardUrl: null,
      error: payload
    };
    default:
      return state;
    }
}

export default reducer;