import DropdownMenu from 'components/dropdowns/DropdownMenu';
import { TextInput } from 'components/form-input';
import React, { useState } from 'react';

const outcomePeriodOptions = [
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Months',
    value: 'months',
  },
  {
    label: 'Years',
    value: 'years',
  },
];

function OutcomePeriod() {
  const [outcomeTimePeriod, setOutcomeTimePeriod] = useState({
    start: 3,
    end: 1,
  });

  const [selectedValue, setSelectedValue] = useState({
    min: outcomePeriodOptions[0],
    max: outcomePeriodOptions[2],
  });

  const handleSelect = (type, option) => {
    setSelectedValue((prevState) => ({
      ...prevState,
      [type === 'min' ? 'min' : 'max']: option,
    }));
  };

  return (
    <div className="d-flex flex-column gap-15" id="custom-textinput-container">
      <div className="d-flex flex-column gap-10">
        <div className="txt size-15 w-600 lh-28 form-input-header">
          Outcome time period
        </div>
        <div className="custom-textinput-container input-wrapper">
          <div className="d-xl-flex gap-50">
            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                Start
              </label>
              <div className="d-lg-flex gap-20">
                <TextInput
                  cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                  type="number"
                  value={outcomeTimePeriod.start}
                  onChange={(e) =>
                    setOutcomeTimePeriod((prev) => ({
                      ...prev,
                      start: Number(e.target.value),
                    }))
                  }
                />

                <div className="input-conatainer">
                  <div className="input-2">
                    <DropdownMenu
                      options={outcomePeriodOptions}
                      selectedOptions={selectedValue?.min}
                      onClickonOption={(option) => handleSelect('min', option)}
                      placeholder="Select"
                      height="35px"
                      optionsPadding="small"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label
                className="mb-1 txt size-14 w-500 lh-20 font-inter primary-1"
                htmlFor="start"
              >
                End
              </label>
              <div className="d-lg-flex gap-20">
                <TextInput
                  cssClass="input-layout-1 input-1 mr-0 txt font-inter primary-1 size-14 w-500 mb-1"
                  type="number"
                  value={outcomeTimePeriod.end}
                  onChange={(e) =>
                    setOutcomeTimePeriod((prev) => ({
                      ...prev,
                      end: Number(e.target.value),
                    }))
                  }
                />
                <div className="input-conatainer">
                  <div className="input-2">
                    <DropdownMenu
                      options={outcomePeriodOptions}
                      selectedOptions={selectedValue?.max}
                      onClickonOption={(option) => handleSelect('max', option)}
                      placeholder="Select"
                      height="35px"
                      optionsPadding="small"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutcomePeriod;
