import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import atroposLogo from '../../../../assets/images/atropos_fav_logo.png';
import { Strings } from 'constants/Strings';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { ButtonCustom } from 'components/form-input/Button';
import { useOrdersPagedata } from 'pages/order/OrderPageContext';
import { AiOutlineMinusCircle, AiOutlineRightCircle } from 'react-icons/ai';
import {
  resetChartrwdErrors,
  resetSuggestions,
} from 'redux/modules/orderDetails/actions';
import { useMixpanel } from 'react-mixpanel-browser';
import PhenotypeUI from 'components/chartRWD/phenotype/Phenotype';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function Phenotype() {
  const {
    showButtonIcons,
    dispatchOrderPageAction,
    ordersPageData: { chatRWD },
    handleButtonAction,
  } = useOrdersPagedata();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const { phenotypeResponse, isOpenAiLoading } = useSelector(
    ({ orderDetails }) => orderDetails,
  );
  const [phenotypeData, setPhenotypeData] = useState('');
  const [isDeleteOptionVisible, setIsDeleteOptionVisible] = useState('');
  const [phenotypeTableResponseTextString, setPhenotypeTableResponseTextString] =
    useState('');
  const [picoObjectValue, setPicoObjectValue] = useState();
  const [phenotypeButtons, setPhenotypeButtons] = useState('');
  const [secondGroupButtonValue, setSecondGroupButtonValue] = useState('');
  const [activePhenotypePicoData, setActivePhenotypePicoData] = useState({});
  const [isEditPhenotypeButtonClicked, setIsEditPhenotypeButtonClicked] = useState(false);
  const [isDatadeleted, setIsDatadeleted] = useState(false);
  const [selectedCodeSetValue, setSelectedCodeSetValue] = useState('');
  const [selectedCodedescriptionValue, setSelectedCodedescriptionValue] = useState('');
  const [secondActiveButton, setSecondActiveButton] = useState('');

  useEffect(() => {
    if (Object.keys(phenotypeData).length === 0) {
      setInitialPhenotypeData();
    }
  }, [phenotypeResponse]);

  useEffect(() => {
    var response = phenotypeData;
    var picoPObj = {};
    var isMatching = false;
    var isError = false;
    var count = 0;
    if (response !== undefined && response !== '' && response !== null) {
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          if (response[key] && Object.keys(response[key]).length !== 0) {
            picoPObj[key] = {};
            isError = false;
            isMatching = false;
            count = 0;
            if (response[key]?.entities?.length === 0) {
              picoPObj[key] = { isError: true, isMatching: isMatching, count: count };
            } else {
              for (let i = 0; i < response[key]?.entities?.length; i++) {
                if (
                  Object.prototype.toString
                    .call(response[key]?.entities[i]?.phenotypes)
                    .indexOf('Array') > -1
                ) {
                  count += response[key]?.entities[i]?.phenotypes[0].code_set.length;
                  var phenotypeRankedObj = response[key]?.entities[i]?.phenotypes[0];
                  if (
                    phenotypeRankedObj?.code_set !== undefined &&
                    phenotypeRankedObj?.code_set.length !== 0
                  ) {
                    phenotypeRankedObj?.code_set.map((code, index) => {
                      isMatching = true;
                      if (Strings.phenotypeError === code.concept_description) {
                        isError = true;
                      } else {
                        isError = false;
                      }
                    });
                  } else {
                    isError = true;
                  }
                }
              }
              picoPObj[key] = { isError: isError, isMatching: isMatching, count: count };
            }
          }
        }
      }
    }
    showPhenotypetext(picoPObj);
  }, [phenotypeResponse, phenotypeData, isDatadeleted]);

  useEffect(() => {
    var response = phenotypeData;
    // setPhenotypesResponseObject(response);
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field: 'userPhenotypes',
        value: response,
      },
    });
  }, [phenotypeData, picoObjectValue]);

  function checkKeyValues(picoObj, keyToCheck, valueToCheck) {
    if (Object.keys(picoObj).length !== 0) {
      for (const key in picoObj) {
        if (picoObj[key][keyToCheck] !== valueToCheck) {
          return false;
        }
      }
      return true;
    }
  }

  const showPhenotypetext = (picoObject) => {
    var isError = false;
    var text = '';
    setPicoObjectValue(picoObject);
    if (Object.keys(picoObject).length !== 0) {
      var isOnePicoPresent = checkKeyValues(picoObject, 'isMatching', true);
      for (const key in picoObject) {
        if (picoObject[key].isError) {
          isError = true;
        }
      }
      if (isOnePicoPresent && isError) {
        text = Strings.phenotypeTableResponseText1;
      } else if (isError) {
        text = Strings.phenotypeTableResponseText2;
      } else {
        text = Strings.phenotypeTableResponseText3;
      }

      setPhenotypeTableResponseTextString(text);
    }
  };

  const setInitialPhenotypeData = () => {
    if (phenotypeResponse?.phenotypes && phenotypeResponse !== undefined)
      setPhenotypeData(JSON.parse(JSON.stringify(phenotypeResponse?.phenotypes)));
  };

  const handlePhenotypeRowClick = (name, key, index) => {
    if (!isEditPhenotypeButtonClicked) {
      const objKey = `${key}${index}`;
      setActivePhenotypePicoData((prevData) => {
        if (Object.keys(prevData).length === 0) {
          return { [objKey]: name };
        } else {
          const newData = { ...prevData };
          if (objKey in newData) {
            delete newData[objKey];
          } else {
            newData[objKey] = name;
          }
          return newData;
        }
      });
    }
  };

  const onClickOfDeleteRow = (entity, key, i) => {
    setIsDeleteOptionVisible('delete');
    mixpanel.track('Phenotype entity delete button clicked', {
      phenotype_entity: entity,
    });
    if (
      selectedCodeSetValue === entity.concept_code &&
      selectedCodedescriptionValue === entity.concept_description
    ) {
      let indexOfObjectToDelete = phenotypeData[key]?.entities[
        i
      ]?.phenotypes[0]?.code_set.findIndex(
        (obj) =>
          obj.concept_code === selectedCodeSetValue &&
          obj.concept_description === selectedCodedescriptionValue,
      );

      // Use splice to remove the object from the array
      if (indexOfObjectToDelete !== -1) {
        var data = phenotypeData;
        data[key]?.entities[i]?.phenotypes[0]?.code_set.splice(indexOfObjectToDelete, 1);
        setPhenotypeData(data);
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'userPhenotypes',
            value: data,
          },
        });

        setIsDatadeleted(true);
        setPicoObjectValue((picoObjectValue) => ({
          ...picoObjectValue,
          count: { ...picoObjectValue[key], count: picoObjectValue[key].count - 1 },
        }));
        mixpanel.track('Phenotype entity deleted', {
          phenotype_entity: entity,
        });
        setIsDeleteOptionVisible('icon');
      }
    } else {
      setIsDatadeleted(false);
      setSelectedCodeSetValue(entity?.concept_code);
      setSelectedCodedescriptionValue(entity?.concept_description);
    }
  };

  function movePhenotypesAccurateToEnd(buttons) {
    if (!buttons) return buttons;

    if (buttons?.PHENOTYPES_ACCURATE) {
      let accurateValue = buttons.PHENOTYPES_ACCURATE;
      delete buttons.PHENOTYPES_ACCURATE;
      buttons.PHENOTYPES_ACCURATE = accurateValue;
    }
    return buttons;
  }

  const showPhenotypeResponseButtons = () => {
    var buttons = [];
    var button = '';

    var phenotypeButtons = phenotypeResponse?.buttons
      ? phenotypeResponse?.buttons
      : phenotypeButtons
      ? phenotypeButtons
      : defaultButtons;
    const buttonsObj = movePhenotypesAccurateToEnd(phenotypeButtons);

    const isError = picoObjectValue
      ? Object.values(picoObjectValue).some((item) => item.isError)
      : false;
    if (buttonsObj) {
      for (const key in buttonsObj) {
        button = (
          <React.Fragment key={key}>
            {key === 'PHENOTYPES_ACCURATE' && <div className="flex-basis-100"> </div>}
            <ButtonCustom
              key={key}
              cssClass={`${
                secondGroupButtonValue === key
                  ? 'active-bg '
                  : secondGroupButtonValue == ''
                  ? ''
                  : 'd-none '
              } ${
                key === aiButtonsEnum.PHENOTYPES_ACCURATE &&
                (Strings.phenotypeTableResponseText2 ===
                  phenotypeTableResponseTextString ||
                  isError)
                  ? 'btn-disabled'
                  : ''
              } blue-btn mb-3 mx-md-1 mx-0`}
              buttonAction={`${buttonsObj[key]} button clicked`}
              id={`bt-${buttonsObj[key]}-button`}
              onClick={() => onClickOfbutton(key)}
              disabled={
                key === aiButtonsEnum.PHENOTYPES_ACCURATE &&
                (Strings.phenotypeTableResponseText2 ===
                  phenotypeTableResponseTextString ||
                  isError)
              }
            >
              <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                {showButtonIcons(key)}
                <span className="text-capitalize">{buttonsObj[key]}</span>
              </div>
            </ButtonCustom>
          </React.Fragment>
        );
        buttons.push(button);
      }
    }
    if (
      Strings.phenotypeTableResponseText2 !== phenotypeTableResponseTextString &&
      phenotypeData !== ''
    ) {
      button = (
        <>
          <ButtonCustom
            key={aiButtonsEnum.START_OVER}
            cssClass="blue-btn mb-3 mx-md-1 mx-0"
            buttonAction="Start Over button clicked"
            id="bt-start-over-phenotypes-button"
            onClick={() => onClickOfbutton(aiButtonsEnum.START_OVER)}
          >
            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
              {showButtonIcons(aiButtonsEnum.START_OVER)}
              <span>Start Over</span>
            </div>
          </ButtonCustom>

          <ButtonCustom
            key={aiButtonsEnum.EDIT_PHENOTYPES}
            cssClass={`${
              secondGroupButtonValue === aiButtonsEnum.EDIT_PHENOTYPES
                ? 'active-bg '
                : secondGroupButtonValue == ''
                ? ''
                : 'd-none '
            } ${
              isEditPhenotypeButtonClicked ? 'd-none' : 'd-block'
            } blue-btn mb-3 mx-md-1 mx-0`}
            buttonAction={`Edit Phenotypes button clicked`}
            id={`bt-edit-phenotypes-button`}
            onClick={() => onClickOfbutton(aiButtonsEnum.EDIT_PHENOTYPES)}
          >
            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
              {showButtonIcons(aiButtonsEnum.EDIT_PHENOTYPES)}
              <span>Edit Phenotypes</span>
            </div>
          </ButtonCustom>
          <ButtonCustom
            key={aiButtonsEnum.UNDO_PHENOTYPE_EDITS}
            cssClass={`${
              secondGroupButtonValue === aiButtonsEnum.UNDO_PHENOTYPE_EDITS
                ? 'active-bg '
                : secondGroupButtonValue == ''
                ? ''
                : 'd-none '
            } ${
              isEditPhenotypeButtonClicked ? 'd-block' : 'd-none'
            } blue-btn mb-3 mx-md-1 mx-0`}
            buttonAction={`Undo Phenotype Edits button clicked`}
            id={`bt-undo-phenotype-edits-button`}
            onClick={() => onClickOfbutton(aiButtonsEnum.UNDO_PHENOTYPE_EDITS)}
          >
            <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
              {showButtonIcons(aiButtonsEnum.UNDO_PHENOTYPE_EDITS)}
              <span>Undo Phenotype Edits</span>
            </div>
          </ButtonCustom>
        </>
      );
      buttons.unshift(button);
    }
    return buttons;
  };

  const showPheotypeTable = (response) => {
    var rows = [];
    var testRows = '';
    if (response) {
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          if (response[key] && Object.keys(response[key]).length !== 0) {
            for (let i = 0; i < response[key]?.entities?.length; i++) {
              const dynamicKey = `${key}${i}`;
              const isRowCollapsed =
                dynamicKey in activePhenotypePicoData &&
                activePhenotypePicoData[dynamicKey] ===
                  response[key]?.entities[i].entity_name;
              if (
                Object.prototype.toString
                  .call(response[key]?.entities[i]?.phenotypes)
                  .indexOf('Array') > -1
              ) {
                var phenotypeRankedObj = response[key]?.entities[i]?.phenotypes[0];
                if (
                  phenotypeRankedObj?.code_set !== undefined &&
                  phenotypeRankedObj?.code_set.length !== 0
                ) {
                  var tableRow1 = [];
                  var tableRow2 = [];
                  var finalrow = [];
                  tableRow1 = (
                    <tr
                      key={
                        phenotypeRankedObj.tql +
                        '__' +
                        response[key]?.entities[i].entity_name
                      }
                      className="wrapper"
                    >
                      <td />
                      <td
                        className="text-upercase question-container"
                        onClick={() =>
                          handlePhenotypeRowClick(
                            response[key]?.entities[i].entity_name,
                            key,
                            i,
                          )
                        }
                      >
                        {key}{' '}
                        <AiOutlineRightCircle
                          className={`arrow ${
                            isEditPhenotypeButtonClicked
                              ? 'active'
                              : isRowCollapsed
                              ? 'active'
                              : ''
                          }`}
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td>{response[key]?.entities[i].entity_name}</td>
                      <td></td>
                    </tr>
                  );
                  tableRow2 = phenotypeRankedObj?.code_set.map((code, index) => {
                    return (
                      <tr
                        key={code.concept_code + '__' + index}
                        className="answer-container"
                        style={
                          isEditPhenotypeButtonClicked
                            ? { display: 'table-row' }
                            : isRowCollapsed
                            ? { display: 'table-row' }
                            : { display: 'none' }
                        }
                      >
                        {isEditPhenotypeButtonClicked &&
                        picoObjectValue[key]?.count > 1 ? (
                          <td>
                            <div
                              className={`delete-rows-icon ${
                                selectedCodeSetValue === code.concept_code &&
                                selectedCodedescriptionValue === code.concept_description
                                  ? 'delete-text'
                                  : ''
                              }`}
                              onClick={() => onClickOfDeleteRow(code, key, i)}
                            >
                              {selectedCodeSetValue === code.concept_code &&
                              selectedCodedescriptionValue ===
                                code.concept_description ? (
                                <span>Delete</span>
                              ) : (
                                <AiOutlineMinusCircle />
                              )}
                            </div>
                          </td>
                        ) : (
                          <td />
                        )}
                        <td className="text-upercase">{key}</td>
                        <td>{code.vocabulary}</td>
                        <td>{code.concept_code}</td>
                        <td>{code.concept_name}</td>
                        <td>
                          {Strings.phenotypeError === code.concept_description ? (
                            <div className="color-error">{code.concept_description}</div>
                          ) : (
                            code.concept_description
                          )}
                        </td>
                      </tr>
                    );
                  });
                  finalrow.push(tableRow1);
                  finalrow.push(tableRow2);
                  testRows = finalrow;
                } else {
                  if (
                    isEditPhenotypeButtonClicked ||
                    (picoObjectValue !== undefined && picoObjectValue[key]?.count > 0)
                  ) {
                    testRows = '';
                  } else {
                    testRows = (
                      <tr key={phenotypeRankedObj.tql + '__'}>
                        <td />
                        <td className="text-upercase">{key}</td>
                        <td></td>
                        <td></td>
                        <td>{phenotypeRankedObj.tql}</td>
                        <td>{phenotypeRankedObj.tql}</td>
                      </tr>
                    );
                  }
                }
                rows.push(testRows);
              }
            }
          }
        }
      }
    }
    return rows;
  };

  const onClickOfbutton = (buttonAction) => {
    dispatch(resetChartrwdErrors());
    switch (buttonAction) {
      case aiButtonsEnum.EDIT_PHENOTYPES:
        setIsEditPhenotypeButtonClicked(true);
        setIsDatadeleted(false);
        setIsDeleteOptionVisible('icon');
        break;
      case aiButtonsEnum.UNDO_PHENOTYPE_EDITS:
        setInitialPhenotypeData();
        setIsEditPhenotypeButtonClicked(false);
        setSelectedCodeSetValue('');
        setSelectedCodedescriptionValue('');
        setIsDatadeleted(false);
        setIsDeleteOptionVisible('');
        break;
      case aiButtonsEnum.PHENOTYPES_ACCURATE:
        dispatchOrderPageAction({
          type: 'order/updateChatRWDField',
          payload: {
            field: 'isGeneralStudyParamVisible',
            value: !chatRWD.isGeneralStudyParamVisible,
          },
        });
        setIsEditPhenotypeButtonClicked(false);
        setSelectedCodeSetValue('');
        setSelectedCodedescriptionValue('');
        if (buttonAction === secondGroupButtonValue) {
          setSecondGroupButtonValue('');
          setSecondActiveButton('');
        } else {
          if (Strings.phenotypeTableResponseText2 === phenotypeTableResponseTextString) {
            return;
          } else {
            setSecondGroupButtonValue(buttonAction);
            setSecondActiveButton(buttonAction);
          }
        }
        break;
      default:
        handleButtonAction({ key: buttonAction });
    }
  };

  return (
    <div className="ai-worflow-container" data-testid="phenotype-section">
      <div className="d-md-flex green-bg px-md-6 px-3 py-md-4 py-3">
        <div className="order-logo-container w-10">
          <img src={atroposLogo} alt="logo" />
        </div>

        {phenotypeResponse?.message && (
          <div className="w-80">{phenotypeResponse?.message}</div>
        )}
        {(phenotypeResponse &&
          Object.keys(phenotypeData).length !== 0 &&
          !isOpenAiLoading) ||
        (phenotypeData !== '' && !isOpenAiLoading) ? (
          <div className={`w-90`}>
            <div className="mb-4">
              The following table is our AI generated list of phenotypes:
            </div>
            <div
              className={`position-relative ${
                isDeleteOptionVisible == 'delete'
                  ? 'table-ml-md'
                  : isDeleteOptionVisible == 'icon'
                  ? 'table-ml-sm '
                  : ''
              }`}
            >
              <div>
                {/* <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>PICO</th>
                      <th>Vocabulary</th>
                      <th>Code</th>
                      <th>Concept Name</th>
                      <th>Concept Description</th>
                    </tr>
                  </thead>
                  <tbody>{showPheotypeTable(phenotypeData)}</tbody>
                </table> */}
                <PhenotypeUI data={phenotypeResponse?.phenotypes} />
              </div>
            </div>
            <div className="mt-4">{phenotypeTableResponseTextString}</div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={`flex-wrap flex-justify-end green-border pt-3 px-md-2 px-2 ${
          !isOpenAiLoading && (phenotypeData !== undefined || phenotypeData !== '')
            ? 'd-md-flex'
            : 'd-none'
        }`}
      >
        {showPhenotypeResponseButtons()}
      </div>
    </div>
  );
}

export default Phenotype;
