import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styles from './phenotype.module.scss';
import PhenotypeTable from './PhenotypeTable';

function Phenotype({ data }) {
  return (
    <Tabs>
      <TabList className={styles.customTabList}>
        {data &&
          Object.keys(data).map(
            (key) =>
              data[key]?.entities?.length > 0 && (
                <Tab key={key}>
                  <span
                    className={`text-capitalize ${
                      Object.keys(data[key]).length === 0 ? 'color-error' : ''
                    }`}
                  >
                    {key}
                  </span>
                </Tab>
              ),
          )}
      </TabList>

      {data &&
        Object.keys(data).map(
          (key) =>
            data[key]?.entities?.length > 0 && (
              <TabPanel key={key}>
                <PhenotypeTable data={data} phenotypes={key} />
              </TabPanel>
            ),
        )}
    </Tabs>
  );
}

export default Phenotype;
