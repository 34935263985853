import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  FETCH_QUICKSIGHT_DASHBOARDS,
  FETCH_QUICKSIGHT_DASHBOARDS_START,
  FETCH_DASHBOARD_BY_ID_REQUEST,
  FETCH_DASHBOARD_BY_ID_START,
} from './types';
import { quicksight } from 'api';
import {
  fetchDashboardByIdFailure,
  fetchDashboardByIdSuccess,
  fetchQuickSightDashboardsComplete,
  fetchQuickSightDashboardsFailure,
} from './action';
import orderFormErrorStr from 'constants/errorStrings';

export function* fetchQuickSightDashboards(action) {
  try {
    yield put({
      type: FETCH_QUICKSIGHT_DASHBOARDS_START,
    });
    const { token } = action.payload; // Extract token from action payload
    const { response, error } = yield call(quicksight.getQuickSightDashboards, {
      token,
    });

    if (!!response) {
      yield put(fetchQuickSightDashboardsComplete(response.data));
    } else {
      yield put(
        fetchQuickSightDashboardsFailure(
          error?.data.message ? error?.data.message : orderFormErrorStr.commonError,
        ),
      );
    }
  } catch (error) {
    yield put(fetchQuickSightDashboardsFailure(orderFormErrorStr.commonError));
  }
}

export function* fetchDashboardById(action) {
  try {
    yield put({
      type: FETCH_DASHBOARD_BY_ID_START,
    });
    const { token, dashboardId } = action.payload; // Extract token from action payload
    const { response, error } = yield call(quicksight.fetchDashboardByIdRequest, {
      token,
      dashboardId,
    });
    if (!!response) {
      yield put(fetchDashboardByIdSuccess(response.data.url));
    } else {
      yield put(
        fetchDashboardByIdFailure(
          error?.data.message ? error?.data.message : orderFormErrorStr.commonError,
        ),
      );
    }
  } catch (error) {
    yield put(fetchQuickSightDashboardsFailure(error));
  }
}

function* watchFetchQuickSightDashboards() {
  yield takeLatest(FETCH_QUICKSIGHT_DASHBOARDS, fetchQuickSightDashboards);
}

function* watchFetchDashboardById() {
  yield takeLatest(FETCH_DASHBOARD_BY_ID_REQUEST, fetchDashboardById);
}

export const sagas = [watchFetchQuickSightDashboards, watchFetchDashboardById];
