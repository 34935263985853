import Checkbox from 'components/form-input/Checkbox';
import RangeSlider from 'components/form-input/RangeSlider';
import React, { useState } from 'react';

const genderList = ['Female', 'Male'];

const todaysDate = new Date();
let initialState = {
  studyYears: {
    minValue: 2015,
    maxValue: todaysDate.getFullYear(),
    min: 2010,
    max: 2024,
  },
  ageRange: {
    minValue: 18,
    maxValue: 90,
    min: 0,
    max: 100,
  },

  followUpPeriod: {
    minValue: 3,
    maxValue: 180,
    min: 0,
    max: 365,
  },
};

function AgeRange() {
  const [rangeValues, setRangeValues] = useState(initialState);

  const handleInput = (e, name) => {
    if (
      rangeValues[name].minValue !== e.minValue ||
      rangeValues[name].maxValue !== e.maxValue
    ) {
      setRangeValues((rangeValues) => ({
        ...rangeValues,
        [name]: { ...rangeValues[name], minValue: e.minValue, maxValue: e.maxValue },
      }));
    }
  };

  const showCheckoxes = (list, name) => {
    const listItems = list?.map((value, index) => (
      <li
        key={index + '-' + value}
        className={`${value === 0 ? ' disabled-filter' : ''}`}
      >
        <Checkbox
          buttonName={value}
          name={name}
          value={value}
          id={`checkbox-mobile-filter${name}-${value}`}
        />
      </li>
    ));
    return <ul className={''}>{listItems}</ul>;
  };

  return (
    <div>
      <div className="input-heading-label">
        Age Range - please select the MIN and MAX ages for your study
      </div>
      <RangeSlider
        min={10}
        max={100}
        minValue={18}
        maxValue={90}
        name="ageRange"
        step={1}
        handleInputChange={handleInput}
      />

      <div className="input-heading-label mt-5">
        Sex - please select the sexes to include
      </div>
      {showCheckoxes(genderList, 'sex')}
    </div>
  );
}

export default AgeRange;
