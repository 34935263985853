import React, { useState } from 'react';
import Table from 'components/Table';
import {
  AiOutlineMinusCircle,
  AiOutlinePlusSquare,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import Codeset from './Codeset';
import styles from './phenotype.module.scss';
import OutcomePeriod from '../studyparameters/OutcomePeriod';
import { Overlay } from 'components/overlaypanel';
import Tag from 'components/tag/Tag';

function OutcomephenotypeTable({ entity, phenotype, onDelete, curEntity }) {
  const [expandRow, setExpandRow] = useState(false);
  const colspan = phenotype === 'population' ? 5 : 6;
  // const phenotypeName = entity?.[0]?.code_set?.[0]?.concept_name || '-';
  const phenotypeName = curEntity?.phenotypes[0]?.entity_result ?? '';
  const isTagsPresent = curEntity?.tags?.length;

  function handleRenderContent(tags) {
    return (
      <div className="d-flex flex-column gap-7">
        <div className="txt size-12">Tags</div>
        <div className="d-flex flex-wrap gap-7">
          {tags.map((tag) => (
            <Tag key={tag} tagName={tag} />
          ))}
        </div>
      </div>
    );
  }

  return entity && entity.length
    ? entity.map((entityPhenotype, index) => (
        <React.Fragment key={index}>
          <Table.Row className={`cursor-pointer ${expandRow ? styles.activeRow : ''}`}>
            <Table.TableData onClick={() => setExpandRow((expand) => !expand)}>
              <div className="d-flex gap-7 cursor-pointer align-items-center">
                {expandRow ? (
                  <AiOutlineMinusCircle className="flex-shrink-0" size={20} />
                ) : (
                  <AiOutlineRightCircle className="flex-shrink-0" size={20} />
                )}
                {curEntity?.entity_name}
              </div>
            </Table.TableData>
            <Table.TableData>{phenotypeName}</Table.TableData>
            <Table.TableData>{entityPhenotype.source}</Table.TableData>
            <Table.TableData>
              {isTagsPresent ? (
                <Overlay title={<AiOutlinePlusSquare size={18} />}>
                  {handleRenderContent(curEntity?.tags)}
                </Overlay>
              ) : (
                ''
              )}
            </Table.TableData>
            <Table.TableData>
              {curEntity.outcome_type ? curEntity.outcome_type : '-'}
            </Table.TableData>
            <Table.TableData className="text-center">
              {entityPhenotype.code_set?.length}
            </Table.TableData>
          </Table.Row>

          {expandRow && (
            <Codeset
              colspan={colspan}
              codeset={entityPhenotype.code_set}
              onDelete={onDelete}
            >
              <Table.Row>
                <Table.TableData colSpan={colspan}>
                  <div className="bg-white px-3">
                    <OutcomePeriod />
                  </div>
                </Table.TableData>
              </Table.Row>
            </Codeset>
          )}
        </React.Fragment>
      ))
    : null;
}

export default OutcomephenotypeTable;
