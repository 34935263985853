import { combineReducers } from 'redux';
import userProfile from './userProfile/reducer';
import orderDetails from './orderDetails/reducer';
import searchOrder from './searchOrder/reducer';
import userSubscription from './userSubscription/reducer';
import notifications from './notifications/reducer';
import quicksight from './quicksight/reducer';

export default combineReducers({
  userProfile,
  orderDetails,
  searchOrder,
  userSubscription,
  notifications,
  quicksight
});
