import axios from 'axios';
import * as user from './user';
import * as order from './order';
import * as searchOrder from './searchOrder';
import * as subscription from './subscription';
import * as notification from './notification';
import * as quicksight from './quicksight'

axios.defaults.baseURL = process.env.REACT_APP_API_PATH;
export { user, order, searchOrder, subscription, notification, quicksight };
