export const labels = {
  orderaPrognostogram: 'Order a Prognostogram',
  modifyandReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
  accessErrorMessage:
    'Oops! It looks like you do not have access to that Prognostogram. If you think this is an error, please reach out to our team at',
  email: 'Support@atroposhealth.com',
  confirmAndSendRequest: 'Confirm and Send Request',
  orderYourFirstPrognostogram: 'Order Your First Prognostogram!',
  scheduleaCall: 'Schedule a Call',
  modifyAndReorder: 'Modify and Reorder',
  editOrder: 'Edit Order',
};
