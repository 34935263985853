import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCheckCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import {
  resetStudyFormats,
  fetchResultThroughOpenAi,
  fetchPicoToPhenotype,
  savePicoOptions,
  clearStudyFormats,
  updateStudyDetails,
  resetPhenotypeData,
  fetchSuggestionsThroughOpenAi,
  incorporateSuggestionsThroughOpenAi,
} from 'redux/modules/orderDetails/actions';
import { BsLightningCharge } from 'react-icons/bs';
import { BiUndo } from 'react-icons/bi';
import { FaMagic, FaRegEdit } from 'react-icons/fa';
import { useToken } from 'hooks/useToken';
import { useOrderPath } from 'hooks/useOrderPath';
import { Strings } from 'constants/Strings';
import orderFormErrorStr from 'constants/errorStrings';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import { dateFormatyyyymmdd } from 'utils/dateFormats';
import { isValidDate } from 'utils/dateFormats';
import { validateEmail } from 'utils/validations';
import { constructQuestionFromStudyFormat } from 'utils/questionTextModifier';

const OrderPageContext = createContext();

const orderPageInitialState = {
  requestOnBehalf: false,
  ordered_for: {
    firstName: '',
    lastName: '',
    email: '',
  },
  selectedProviderMotivation: {},
  patientInfo: {
    patientLastName: '',
    patientBirthDate: '',
  },
  selectedDataSourceOption: [],
  isConfidential: false,
  urgencyType: 'Not Urgent',
  questionTypeSelection: 1,
  errorsObject: {},
  isConfirmationModalOpen: false,
  isOrderSubmitted: false,
  picotSwitch: {
    value: false,
  },
  sharingValue: 'Library',
  loadingTitle: Strings.generatingPicotMessage,
  PICOOrderDetails: {
    questionTitle: '',
    clinicalQuestion: '',
    initialClinicalQuestions: '',
    population: '',
    intervention: '',
    control: '',
    outcome: '',
    timeframe: '',
  },
  chatRWD: {
    isManualInputMode: false,
    isManualEnterFromPhenotype: false,
    hasWorkflowStarted: false,
    hasUserSelectedManualWorkflow: false,
    questionTitle: '',
    clinicalQuestion: '',
    initialClinicalQuestions: '',
    formValues: {},
    isClinicalRetry: false,
    selectedSuggestionsOptions: [],
    hasClickedOnStartOver: false,
    isGeneralStudyParamVisible: false,
    userPhenotypes: {},
    userAction: {
      action: undefined,
      options: undefined,
    },
  },
};

function ordersReducer(state, action) {
  switch (action.type) {
    case 'order/toggleRequestOnBehalf':
      return {
        ...state,
        requestOnBehalf: action.payload,
      };
    case 'order/updateOrderedFor':
      return {
        ...state,
        ordered_for: {
          ...state.ordered_for,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setOrderedFor':
      return {
        ...state,
        ordered_for: {
          ...state.ordered_for,
          ...action.payload,
        },
      };
    case 'order/clearOrderedFor':
      return {
        ...state,
        ordered_for: {
          firstName: '',
          lastName: '',
          email: '',
        },
      };
    case 'order/setSelectedProviderMotivation':
      return {
        ...state,
        selectedProviderMotivation: action.payload,
      };
    case 'order/patientInfo':
      return {
        ...state,
        patientInfo: {
          ...state.patientInfo,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setselectedDataSourceOption':
      return {
        ...state,
        selectedDataSourceOption: action.payload,
      };
    case 'order/setConfidential':
      return {
        ...state,
        isConfidential: action.payload,
      };
    case 'order/setUrgencyType':
      return {
        ...state,
        urgencyType: action.payload,
      };
    case 'order/setQuestionTypeSelection':
      return {
        ...state,
        questionTypeSelection: action.payload,
      };
    case 'order/setFormErrorsObject':
      return {
        ...state,
        errorsObject: {
          ...state.errorsObject,
          ...action.payload,
        },
      };
    case 'order/setFormErrorByKey':
      return {
        ...state,
        errorsObject: {
          ...state.errorsObject,
          [action.payload.key]: action.payload.error,
        },
      };
    case 'order/setFormErrorText':
      return {
        ...state,
        errorText: action.payload,
      };
    case 'order/setNavigationConfirmationModalOpen':
      return {
        ...state,
        isConfirmationModalOpen: action.payload,
      };
    case 'order/seIsOrderSubmitted':
      return {
        ...state,
        isOrderSubmitted: action.payload,
      };
    case 'order/PICOOrderDetails':
      return {
        ...state,
        PICOOrderDetails: {
          ...state.PICOOrderDetails,
          ...action.payload,
        },
      };
    case 'order/updatePICOOrderDetailsField':
      return {
        ...state,
        PICOOrderDetails: {
          ...state.PICOOrderDetails,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/setChatRWDData':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          ...action.payload,
        },
      };
    case 'order/updateChatRWDField':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'order/updateChatRWDFormValuesByField':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {
            ...state.chatRWD.formValues,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case 'order/updateChatRWDFormValues':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {
            ...state.chatRWD.formValues,
            ...action.payload,
          },
        },
      };
    case 'order/clearChatRWDFormValues':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          formValues: {},
        },
      };
    case 'order/setLoadingTitle':
      return {
        ...state,
        loadingTitle: action.payload,
      };
    case 'order/setSuggestionsOptions':
      const dataArray = Object.entries(action.payload);
      dataArray.sort((a, b) => {
        const numA = parseInt(a[1].split('.')[0]);
        const numB = parseInt(b[1].split('.')[0]);
        return numA - numB;
      });
      const sortedData = {};
      dataArray.forEach((item, index) => {
        sortedData[index] = item[1];
      });
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          selectedSuggestionsOptions: [
            ...state.chatRWD.selectedSuggestionsOptions,
            sortedData,
          ],
        },
      };

    case 'order/setUserAction':
      return {
        ...state,
        chatRWD: {
          ...state.chatRWD,
          userAction: {
            action: action.payload.userAction,
            options: action.payload.options,
          },
        },
      };

    case 'order/resetChatRWDData':
      return {
        ...state,
        chatRWD: orderPageInitialState.chatRWD,
      };
    default:
      return state;
  }
}

function motivationRequiresPatientData(providerMotivation) {
  return providerMotivation && providerMotivation?.label === 'Patient Care';
}

function OrderPageProvider({ children }) {
  const token = useToken();
  const params = useParams();
  const dispatch = useDispatch();
  const orderTypePath = useOrderPath();

  const { datasetList } = useSelector(({ userSubscription }) => userSubscription);
  const {
    singleOrderData,
    motivations,
    uploadedFilesList,
    aIResponseErrors,
    studyFormats,
    PICOGuid,
    studyFormat: currentStudyFormat,
    phenotypeResponse,
  } = useSelector(({ orderDetails }) => orderDetails);
  const { userData, usersConfig } = useSelector(({ userProfile }) => userProfile);

  const [ordersPageData, dispatchOrderPageAction] = useReducer(
    ordersReducer,
    orderPageInitialState,
  );

  const isReorder =
    singleOrderData?.guid &&
    singleOrderData?.status === 'Pending' &&
    orderTypePath === 'edit';

  useEffect(() => {
    if (
      (studyFormats && studyFormats.length > 0) ||
      (aIResponseErrors && Object.keys(aIResponseErrors).length > 0) ||
      phenotypeResponse ||
      PICOGuid
    ) {
      handleReset();
    }
  }, []);

  useEffect(() => {
    if (singleOrderData && singleOrderData.product_case_type) {
      const payload =
        singleOrderData.product_case_type === 'ChatRWD' && !isReorder ? 3 : 1;

      dispatchOrderPageAction({
        type: 'order/setQuestionTypeSelection',
        payload,
      });
    }
  }, [singleOrderData, dispatchOrderPageAction]);

  useEffect(() => {
    if (
      params &&
      params.guid &&
      singleOrderData &&
      Object.keys(singleOrderData).length > 0
    ) {
      const {
        clinical_questions,
        initial_clinical_questions,
        population,
        intervention,
        control,
        outcome,
        timeframe,
        ai_search_history,
        question_title,
      } = singleOrderData;

      const data = {
        clinicalQuestion: clinical_questions,
        initialClinicalQuestions: initial_clinical_questions,
        questionTitle: question_title || clinical_questions,
      };
      dispatchOrderPageAction({
        type: 'order/PICOOrderDetails',
        payload: {
          ...data,
          population,
          intervention,
          control,
          outcome,
          timeframe,
        },
      });

      if (
        ai_search_history &&
        ai_search_history.guid &&
        ai_search_history.response_pico
      ) {
        const dataObj = {
          ...data,
          hasWorkflowStarted: true,
          selectedSuggestionsOptions: [{ option: 'PICOT Accurate' }],
        };
        handleUpdateChatRWDData(dataObj);
      } else {
        handleUpdateChatRWDData({
          ...data,
          hasWorkflowStarted: false,
          selectedSuggestionsOptions: [],
          formValues: {
            population,
            intervention,
            control,
            outcome,
          },
        });
      }
    }
  }, [params, singleOrderData, dispatchOrderPageAction, dispatchOrderPageAction]);

  useEffect(() => {
    if (aIResponseErrors && Object.keys(aIResponseErrors).length > 0) {
      const lastSelectedOptionIndex =
        ordersPageData?.chatRWD?.selectedSuggestionsOptions?.length - 1;

      if (aIResponseErrors.openAiError) {
        dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
        return;
      }

      if (
        aIResponseErrors.openAiSuggestionError ||
        aIResponseErrors.openAiSuggestionIncorporationError ||
        aIResponseErrors.openAiPhenotypeError
      ) {
        if (lastSelectedOptionIndex === studyFormats.length - 1) {
          removeSelectedOptionWithIndex(lastSelectedOptionIndex);
        }
        return;
      }

      if (aIResponseErrors.picoSavingError) {
        if (studyFormats.length === 0) {
          dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
          return;
        }

        if (studyFormats.length > 0) {
          if (lastSelectedOptionIndex === studyFormats.length - 1)
            removeSelectedOptionWithIndex(lastSelectedOptionIndex);
        } else {
          dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
        }
      }
    }
  }, [
    aIResponseErrors,
    dispatchOrderPageAction,
    ordersPageData?.chatRWD?.selectedSuggestionsOptions,
    studyFormats,
  ]);

  let dataSourceArr = [];
  if (!isReorder && datasetList?.length > 0) {
    ordersPageData?.selectedDataSourceOption
      ?.filter((dataSource) => dataSource.isChecked)
      .map(({ label, isChecked, value, group, ...rest }) => dataSourceArr.push(rest));
  }

  let documentPaths = [];
  if (
    uploadedFilesList &&
    uploadedFilesList?.length > 0 &&
    ordersPageData?.questionTypeSelection !== 3
  ) {
    uploadedFilesList.forEach((file) => {
      const obj = {
        key_path: file.key_path,
        phi_disclaimer: file.phi_disclaimer,
        is_uploaded: file.is_uploaded,
      };
      documentPaths.push(obj);
    });
  }

  const orderPayloadData = {
    user: {
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
    },
    institute: {
      institute_name: userData?.institute.name,
      institute_guid: userData?.institute.guid,
    },
    patient:
      motivationRequiresPatientData(ordersPageData?.selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
        ? {
            last_name: ordersPageData?.patientInfo?.patientLastName,
            birth_date: ordersPageData?.patientInfo?.patientBirthDate
              ? dateFormatyyyymmdd(ordersPageData?.patientInfo?.patientBirthDate)
              : '',
          }
        : undefined,
    order: {
      picot_status: ordersPageData?.picotSwitch?.value,
      data_source:
        ordersPageData?.questionTypeSelection == 3
          ? dataSourceArr
          : !isReorder
          ? dataSourceArr
          : [singleOrderData?.data_source_object],
      sharing_type: ordersPageData?.sharingValue,
      confidential: ordersPageData?.isConfidential,
      is_urgent: ordersPageData?.urgencyType === 'Urgent' ? true : false,
      documents: ordersPageData?.questionTypeSelection !== 3 ? documentPaths : [],
      phi_disclaimer: documentPaths.length ? true : null,
      previous_order_guid: singleOrderData?.guid,
      motivations: motivations
        ? Object.fromEntries(
            motivations.map(({ category, value }) => [
              category,
              ordersPageData?.selectedProviderMotivation
                ? ordersPageData?.selectedProviderMotivation.label === category
                  ? true
                  : false
                : false,
            ]),
          )
        : {},
      ordered_for: ordersPageData?.requestOnBehalf
        ? {
            first_name: ordersPageData?.ordered_for?.firstName,
            last_name: ordersPageData?.ordered_for?.lastName,
            email: ordersPageData?.ordered_for?.email,
          }
        : undefined,
      previous_order_guid: params?.guid,
    },
  };

  function handleReset() {
    dispatch(resetStudyFormats());
    dispatchOrderPageAction({ type: 'order/resetChatRWDData' });
  }

  function isFormValid() {
    const {
      requestOnBehalf,
      selectedProviderMotivation,
      patientInfo,
      selectedDataSourceOption,
      ordered_for,
    } = ordersPageData;

    let errorsObject = {};
    if (requestOnBehalf) {
      const { firstName, lastName, email } = ordered_for;
      if (!firstName) {
        errorsObject.firstName = Strings.orderFirstNameError;
      }
      if (!lastName) {
        errorsObject.lastName = Strings.orderLastNameError;
      }

      if (!validateEmail(email)) {
        errorsObject.email = orderFormErrorStr.email;
      }
    }

    if (
      motivationRequiresPatientData(selectedProviderMotivation) &&
      usersConfig?.configuration?.prognostogram?.motivations?.patient_care
    ) {
      const { patientLastName, patientBirthDate } = patientInfo;
      const date = new Date(patientInfo?.patientBirthDate);
      if (!patientLastName) {
        errorsObject.patientLastName = Strings.patientLastNameError;
      }
      if (!patientBirthDate && !isValidDate(date)) {
        errorsObject.patientBirthDate = Strings.patientBirthDateError;
      }
    }
    if (!selectedProviderMotivation?.label) {
      errorsObject.motivation = Strings.motivationError;
    }

    if (
      !selectedDataSourceOption?.length ||
      selectedDataSourceOption?.every((opt) => !opt.isChecked)
    ) {
      errorsObject.dataSourceSelectionError = Strings.dataSourceSelectionError;
    }

    dispatchOrderPageAction({
      type: 'order/setFormErrorsObject',
      payload: errorsObject,
    });

    const errorText = Object.values(errorsObject).find((value) => value);

    return !errorText;
  }

  function showButtonIcons(buttonAction) {
    switch (buttonAction) {
      case aiButtonsEnum.PICOT_ACCURATE:
      case aiButtonsEnum.PHENOTYPES_ACCURATE:
        return <AiFillCheckCircle />;
      default:
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
        return <BsLightningCharge />;
      case aiButtonsEnum.MANUALLY_EDIT:
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
      case aiButtonsEnum.MANUALLY_ENTER_PICO:
        return <FaRegEdit />;
      case aiButtonsEnum.REGENERATE_PICOT:
        return;
      case aiButtonsEnum.GENERATE_PICO:
        return <FaMagic />;
      case aiButtonsEnum.EDIT_PHENOTYPES:
        return <AiOutlineMinusCircle />;
      case aiButtonsEnum.UNDO_PHENOTYPE_EDITS:
        return <BiUndo />;
      case aiButtonsEnum.START_OVER:
        return;
    }
  }

  function handleLoadingStrings(key) {
    const loadingMessages = {
      [aiButtonsEnum.GENERATE_PICO]: Strings.generatingPicotMessage,
      [aiButtonsEnum.KEEP_ORIGINAL]: Strings.generatingPicotMessage,
      [aiButtonsEnum.REGENERATE_PICOT]: Strings.generatingPicotMessage,
      [aiButtonsEnum.REGENERATE_SUGGESTIONS]: Strings.suggestionGeneration,
      [aiButtonsEnum.SUGGEST_IMPROVEMENTS]: Strings.suggestionGeneration,
      [aiButtonsEnum.SAVE_PICO]: Strings.savingsPicotMessage,
      [aiButtonsEnum.SAVE_PHENOTYPE_TO_PICO]: Strings.savingsPicotMessage,
      [aiButtonsEnum.PICOT_ACCURATE]: Strings.phenotypeGeneration,
      [aiButtonsEnum.INCORPORATE_CHANGES]: Strings.incorporatingSuggestionMessage,
    };
    const payload = loadingMessages[key];
    if (payload) {
      dispatchOrderPageAction({
        type: 'order/setLoadingTitle',
        payload,
      });
    }
  }

  const {
    userAction,
    selectedSuggestionsOptions,
    initialClinicalQuestions,
    questionTitle,
    isClinicalRetry,
    formValues,
  } = ordersPageData.chatRWD;

  function handleButtonAction({ key: action, index, options }) {
    handleLoadingStrings(action);
    switch (action) {
      case aiButtonsEnum.GENERATE_PICO:
      case aiButtonsEnum.REGENERATE_PICOT:
        handleUpdateChatRWDField({
          field: 'userAction',
          value: { action, option: undefined },
        });
        handleGeneratePICOWorkflow();
        break;
      case aiButtonsEnum.PICOT_ACCURATE:
        fetchPhenotype();
        break;
      case aiButtonsEnum.MANUALLY_ENTER_PICO:
        handleUpdateChatRWDField({ field: 'isManualInputMode', value: true });
        break;
      case aiButtonsEnum.MANUALLY_SAVE_PICO:
        handleManualSavePICOWorkflow();
        break;
      case aiButtonsEnum.START_OVER:
        handleUpdateChatRWDField({ field: 'hasClickedOnStartOver', value: true });
        break;
      case aiButtonsEnum.MANUALLY_EDIT_PICO:
        handleUpdateChatRWDField({ field: 'isManualEnterFromPhenotype', value: true });
        break;
      case aiButtonsEnum.CANCEL_PICO_EDIT:
        handleUpdateChatRWDField({ field: 'isManualEnterFromPhenotype', value: false });
        break;
      case aiButtonsEnum.SAVE_PHENOTYPE_TO_PICO:
        dispatch(clearStudyFormats());
        handleUpdateChatRWDData({
          selectedSuggestionsOptions: [],
          isManualEnterFromPhenotype: false,
        });
        handleSavePhenotypeToPICOWorkflow();
        break;
      case aiButtonsEnum.MANUALLY_EDIT_QUESTION:
        handleUpdateChatRWDData({
          hasWorkflowStarted: false,
          isClinicalRetry: true,
        });
        break;
      case aiButtonsEnum.MANUALLY_EDIT:
        handleCurrentStudyFormatUpdate({ isShow: true, index });
        break;
      case aiButtonsEnum.CANCEL_CURRENT_FORMAT_EDIT:
        handleCurrentStudyFormatUpdate({ isShow: false, index });
        break;
      case aiButtonsEnum.SAVE_CURRENT_FORMAT_EDIT:
        handleSaveCurrentFormatEdit(index);
        break;
      case aiButtonsEnum.MANUALLY_EDIT_PICOT_ON_ERROR:
        handleManualEditPICOTonErrorWorkflow();
        break;
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
      case aiButtonsEnum.REGENERATE_SUGGESTIONS:
        fetchSuggestionsResponseThroughOpenAi();
        break;
      case aiButtonsEnum.INCORPORATE_CHANGES:
        const curSuggestions = studyFormats?.[studyFormats.length - 1]?.suggestions || [];
        handleSuggestionIncorporation({ options, curSuggestions });
        break;
      case aiButtonsEnum.KEEP_ORIGINAL:
        handleSaveEditStudyFormat(currentStudyFormat);
        break;
      case aiButtonsEnum.SUGGESTIONS_MANUALLY_EDIT:
        handleSuggestionManuallyEdit();
        break;
      case aiButtonsEnum.TRY_AGAIN:
        handleTryAgainWorkflow();
        break;
      default:
        console.log(action, 'wrong option');
    }
  }

  function handleTryAgainWorkflow() {
    switch (userAction.action) {
      case aiButtonsEnum.GENERATE_PICO:
      case aiButtonsEnum.PICOT_ACCURATE:
        handleButtonAction({ key: userAction.action });
        break;
      case aiButtonsEnum.SUGGEST_IMPROVEMENTS:
        handleRemoveErrorFrame();
        fetchSuggestionsResponseThroughOpenAi();
        break;
      case aiButtonsEnum.INCORPORATE_CHANGES:
        handleRemoveErrorFrame();
        const curSuggestions = studyFormats?.[studyFormats.length - 2]?.suggestions || [];
        handleSuggestionIncorporation({
          options: { ...userAction?.options?.options },
          curSuggestions,
        });
        break;
      default:
        break;
    }
  }

  function handleUpdateChatRWDField({ field, value }) {
    dispatchOrderPageAction({
      type: 'order/updateChatRWDField',
      payload: {
        field,
        value,
      },
    });
  }

  function handleUpdateChatRWDData(data) {
    dispatchOrderPageAction({
      type: 'order/setChatRWDData',
      payload: data,
    });
  }

  function handleUpdatePICOField({ field, value }) {
    dispatchOrderPageAction({
      type: 'order/updatePICOOrderDetailsField',
      payload: {
        field,
        value,
      },
    });
  }

  function handleCurrentStudyFormatUpdate({ isShow, index }) {
    const studyFormatsArr = structuredClone(studyFormats);
    const curFormat = studyFormatsArr[index];
    curFormat.isEdit = isShow;
    dispatch(updateStudyDetails(studyFormatsArr));
  }

  function removeStudyFormatWithIndex(index) {
    const studyFormatsArr = structuredClone(studyFormats);
    studyFormatsArr.splice(index, 1);
    return studyFormatsArr;
  }

  function removeSelectedOptionWithIndex(index) {
    const selectedOptions = [...selectedSuggestionsOptions];
    selectedOptions.splice(index, 1);
    handleUpdateChatRWDField({
      field: 'selectedSuggestionsOptions',
      value: selectedOptions,
    });
  }

  function fetchOrderResponseThroughOpenAi({ questionText, isClinicalRetry }) {
    if (token) {
      dispatch(
        fetchResultThroughOpenAi({
          params: {
            question: questionText,
            previous_pico_guid: PICOGuid,
            clinical_retry: isClinicalRetry,
          },
          token: token,
        }),
      );
    } else {
      console.error('Token is not available');
    }
  }

  function fetchPhenotype() {
    if (token) {
      dispatch(
        fetchPicoToPhenotype({
          params: {
            pico_guid: PICOGuid,
          },
          token: token,
        }),
      );
    } else {
      console.error('Token is not available');
    }
  }

  function handleGeneratePICOWorkflow() {
    handleUpdateChatRWDField({ field: 'hasWorkflowStarted', value: true });
    if (!initialClinicalQuestions) {
      handleUpdateChatRWDField({
        field: 'initialClinicalQuestions',
        value: questionTitle,
      });
    }
    fetchOrderResponseThroughOpenAi({
      questionText: questionTitle,
      isClinicalRetry,
    });
    if (isClinicalRetry) {
      handleUpdateChatRWDField({ field: 'isClinicalRetry', value: false });
    }
  }

  function handleSaveCurrentFormatEdit(index) {
    handleCurrentStudyFormatUpdate({ isShow: false, index });
    const updatedStudyFormats = removeStudyFormatWithIndex(index);
    dispatch(updateStudyDetails(updatedStudyFormats));
    handlePICOQuestionOnChange();
    handleSaveEditStudyFormat(formValues);
  }

  function handleManualSavePICOWorkflow() {
    const question = handlePICOQuestionOnChange();

    let data = {
      hasWorkflowStarted: true,
      hasUserSelectedManualWorkflow: true,
      isManualInputMode: false,
      questionTitle: question,
    };
    if (!initialClinicalQuestions) data['initialClinicalQuestions'] = question;
    if (studyFormats && studyFormats.length > 0) {
      data['selectedSuggestionsOptions'] = [];
      dispatch(resetStudyFormats());
    }
    handleUpdateChatRWDData(data);
    handleSaveEditStudyFormat(formValues);
  }

  function handleSavePhenotypeToPICOWorkflow() {
    handlePICOQuestionOnChange();
    handleSaveEditStudyFormat(formValues);
  }

  function handlePICOQuestionOnChange() {
    const question = constructQuestionFromStudyFormat(formValues);
    handleUpdatePICOField({ field: 'clinicalQuestion', value: question });
    return question;
  }

  function handleSaveEditStudyFormat(formValues) {
    dispatch(
      savePicoOptions({
        params: {
          previous_pico_guid: PICOGuid,
          study_format: {
            ...formValues,
          },
        },
        token,
      }),
    );
  }

  function scrollToBottom() {
    setTimeout(
      () =>
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth',
        }),
      100,
    );
  }

  function fetchSuggestionsResponseThroughOpenAi() {
    dispatch(
      fetchSuggestionsThroughOpenAi({
        params: {
          pico_guid: PICOGuid,
        },
        token,
      }),
    );
  }

  function extractPICOOptions(option) {
    const picoOptions = {};
    for (const key in option) {
      picoOptions[key] = option[key].replace(/^\d+\. /, '');
    }
    return picoOptions;
  }

  function handleSuggestionIncorporation({ options, curSuggestions }) {
    const selectedOptions = extractPICOOptions(options);
    const selectedSuggestions = curSuggestions?.filter((item) =>
      Object.values(selectedOptions).includes(item.name),
    );
    handleIncorporateOpenAISuggestions(selectedSuggestions);
  }

  function handleIncorporateOpenAISuggestions(suggestions) {
    dispatch(
      incorporateSuggestionsThroughOpenAi({
        params: {
          pico_guid: PICOGuid,
          selected_suggestions: suggestions,
        },
        token,
      }),
    );
  }

  function handleManualEditPICOTonErrorWorkflow() {
    const curFormatindex = studyFormats.findIndex(
      (studyFormat) => studyFormat.guid === PICOGuid,
    );
    if (curFormatindex >= 0) {
      handleCurrentStudyFormatUpdate({ isShow: true, index: curFormatindex });
      dispatch(resetPhenotypeData());
      removeSelectedOptionWithIndex(curFormatindex);
    }
  }

  function handleRemoveErrorFrame() {
    const studyFormatsArr = [...studyFormats];
    studyFormatsArr.pop();
    dispatch(updateStudyDetails(studyFormatsArr));
  }

  function handleSuggestionManuallyEdit() {
    const { options } = userAction;
    const prevStudyFormatIndex = studyFormats.findIndex(
      (studyFormat) => studyFormat.guid === options.PICOGuid,
    );

    if (prevStudyFormatIndex >= 0) {
      const arr = studyFormats.slice(0, prevStudyFormatIndex + 1);
      arr[prevStudyFormatIndex].isEdit = true;
      arr.isEdit = true;
      const selectedOptionsArr = selectedSuggestionsOptions.slice(
        0,
        prevStudyFormatIndex,
      );
      handleUpdateChatRWDField({
        field: 'selectedSuggestionsOptions',
        value: selectedOptionsArr,
      });
      dispatch(updateStudyDetails(arr));
    }
  }

  return (
    <OrderPageContext.Provider
      value={{
        ordersPageData,
        dispatchOrderPageAction,
        isFormValid,
        orderPayloadData,
        isReorder,
        orderTypePath,
        showButtonIcons,
        handleLoadingStrings,
        handleReset,
        handleButtonAction,
        token,
      }}
    >
      {children}
    </OrderPageContext.Provider>
  );
}

function useOrdersPagedata() {
  const context = useContext(OrderPageContext);
  if (context === undefined) throw new Error('context used outside');
  return context;
}

export { OrderPageProvider, useOrdersPagedata };
