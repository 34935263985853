import {
  FETCH_QUICKSIGHT_DASHBOARDS, 
  FETCH_QUICKSIGHT_DASHBOARDS_COMPLETE,
  FETCH_QUICKSIGHT_DASHBOARDS_FAILURE,
  FETCH_DASHBOARD_BY_ID_REQUEST,
  FETCH_DASHBOARD_BY_ID_SUCCESS,
  FETCH_DASHBOARD_BY_ID_FAILURE
} from "./types";


export const fetchQuickSightDashboards = (payload) => ({
  type: FETCH_QUICKSIGHT_DASHBOARDS,
  payload
});

export const fetchQuickSightDashboardsComplete = (payload) => ({
  type:FETCH_QUICKSIGHT_DASHBOARDS_COMPLETE,
  payload
})

export const fetchQuickSightDashboardsFailure = (payload) => ({
  type:FETCH_QUICKSIGHT_DASHBOARDS_FAILURE,
  payload
})

export const fetchDashboardByIdRequest = (payload) => ({
  type:FETCH_DASHBOARD_BY_ID_REQUEST,
  payload
})

export const fetchDashboardByIdSuccess = (payload) => ({
  type:FETCH_DASHBOARD_BY_ID_SUCCESS,
  payload
})

export const fetchDashboardByIdFailure = (payload) => ({
  type:FETCH_DASHBOARD_BY_ID_FAILURE,
  payload
})