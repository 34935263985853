
import axios from 'axios';

export const getQuickSightDashboards = async ({ token }) => {
  return axios({
    method: 'get',
    url: 'quicksight/dashboards/',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

	.then((response) => ({ response: response.data }))
	.catch((error) => ({ error: error.response }));
};

export const fetchDashboardByIdRequest = async ({ token, dashboardId }) => {
  return axios({
    method: 'get',
    url: `quicksight/dashboards/${dashboardId}/`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })

	.then((response) => ({ response: response.data }))
	.catch((error) => ({ error: error.response }));
};