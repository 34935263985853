import React, { useState } from 'react';
import {
  AiOutlineMinusCircle,
  AiOutlinePlusSquare,
  AiOutlineRightCircle,
} from 'react-icons/ai';
import Table from 'components/Table';
import Codeset from './Codeset';
import styles from './phenotype.module.scss';
import { Overlay } from 'components/overlaypanel';
import Tag from 'components/tag/Tag';

function PICphenotypeTable({ entity, phenotype, onDelete, curEntity }) {
  const [expandRow, setExpandRow] = useState(false);
  const colspan = phenotype === 'population' ? 5 : 6;
  const isNotPopulationPhenotype = phenotype == 'intervention' || phenotype == 'control';
  const isPICTable =
    phenotype == 'population' ||
    phenotype == 'intervention' ||
    phenotype == 'control' ||
    phenotype == 'outcome' ||
    phenotype == 'outcomes';

  const isTagsPresent = curEntity?.tags?.length;
  const phenotypeName = curEntity?.phenotypes[0]?.entity_result ?? '';

  function handleRenderContent(tags) {
    return (
      <div className="d-flex flex-column gap-7">
        <div className="txt size-12">Tags</div>
        <div className="d-flex flex-wrap gap-7">
          {tags.map((tag) => (
            <Tag key={tag} tagName={tag} />
          ))}
        </div>
      </div>
    );
  }

  // const phenotypeName =
  //   entity && entity.length && entity[0].code_set.length
  //     ? entity[0]?.code_set[0]?.concept_name
  //     : '-';

  return entity && entity.length
    ? entity.map((entityPhenotype, index) => (
        <React.Fragment key={index}>
          <Table.Row className={`cursor-pointer ${expandRow ? styles.activeRow : ''}`}>
            <Table.TableData>
              <div
                className="d-flex gap-7 cursor-pointer align-items-center"
                onClick={() => setExpandRow((expand) => !expand)}
              >
                {expandRow ? (
                  <AiOutlineMinusCircle className="flex-shrink-0" size={20} />
                ) : (
                  <AiOutlineRightCircle className="flex-shrink-0" size={20} />
                )}
                {curEntity?.entity_name}
              </div>
            </Table.TableData>
            <Table.TableData>{phenotypeName}</Table.TableData>
            <Table.TableData>{entityPhenotype.source}</Table.TableData>

            {isPICTable && (
              <Table.TableData>
                {isTagsPresent ? (
                  <Overlay title={<AiOutlinePlusSquare size={18} />}>
                    {handleRenderContent(curEntity?.tags)}
                  </Overlay>
                ) : (
                  ''
                )}
              </Table.TableData>
            )}

            {isNotPopulationPhenotype && (
              <Table.TableData>{curEntity?.subset ? 'TRUE' : 'FALSE'}</Table.TableData>
            )}
            <Table.TableData className="text-center">
              {entityPhenotype.code_set?.length}
            </Table.TableData>
          </Table.Row>

          {expandRow && (
            <Codeset
              onDelete={onDelete}
              colspan={colspan}
              codeset={entityPhenotype.code_set}
              phenotype={phenotype}
            />
          )}
        </React.Fragment>
      ))
    : null;
}

export default PICphenotypeTable;
