import React, { useState } from 'react';
import atroposLogo from '../../assets/images/atropos_fav_logo.png';
import { ButtonCustom } from 'components/form-input/Button';
import { Strings } from 'constants/Strings';
import { AiFillCheckCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { BsLightningCharge } from 'react-icons/bs';
import { FaMagic, FaRegEdit } from 'react-icons/fa';
import { BiUndo } from 'react-icons/bi';
import { aiButtonsEnum } from 'constants/enum/aiButtonsEnum';
import PICOEditForm from './PICOEditForm';
import PicotDataDisplay from './PicotDataDisplay';
import { useSelector } from 'react-redux';

const defaultButtons = {
  TRY_AGAIN: 'Try Again',
  START_OVER: 'Start Over',
};

function StudyDetails({
  suggestionObject = {},
  customButtons = {},
  dispatch,
  onClickSuggestionOption,
  selectedSuggestionFromSuggestion = {},
  multiSelect = false,
  selectedSuggestionsOptions = undefined,
  index = null,
  onPICOSaveForm,
}) {
  const { buttons, suggestions, picotData, updated_pico, isEdit } = suggestionObject;
  const [options, setOptions] = useState([]);
  const { PICOGuid } = useSelector(({ orderDetails }) => orderDetails);
  function handleButtonClick(option, key) {
    if (
      key === 'TRY_AGAIN' ||
      key === 'START_OVER' ||
      key === 'MANUALLY_EDIT_QUESTION' ||
      key === 'SUGGESTIONS_MANUALLY_EDIT'
    ) {
      onClickSuggestionOption(options, key, index);
      return;
    }

    if (multiSelect) {
      switch (key) {
        case 'INCORPORATE_CHANGES':
          dispatch({
            type: 'setLoadingTitle',
            payload: Strings.incorporatingSuggestionMessage,
          });
          const newObj = { ...options };
          dispatch({ type: 'setSuggestionsOptions', payload: newObj });
          setOptions([]);
          onClickSuggestionOption(newObj, key, index);
          dispatch({
            type: 'setUserAction',
            payload: { userAction: key, options: { options, PICOGuid } },
          });
          break;
        case 'KEEP_ORIGINAL':
        case 'REGENERATE_SUGGESTIONS':
          dispatch({ type: 'setSuggestionsOptions', payload: { option } });
          onClickSuggestionOption(option, key, index);

          dispatch({
            type: 'setUserAction',
            payload: { userAction: key, options: undefined },
          });
          break;
        default:
          if (!options.includes(option)) {
            setOptions((op) => [...op, option]);
          } else {
            const newOptions = options.filter((op) => op !== option);
            setOptions(newOptions);
          }
          break;
      }
    } else {
      if (!(key === 'MANUALLY_EDIT' || key === 'START_OVER')) {
        dispatch({ type: 'setSuggestionsOptions', payload: { option } });

        dispatch({
          type: 'setUserAction',
          payload: {
            userAction: key,
            options: PICOGuid,
          },
        });
      }
      onClickSuggestionOption(option, key, index);
    }
  }

  const showButtonIcons = (buttonAction) => {
    switch (buttonAction) {
      case 'PICOT_ACCURATE':
      case 'PHENOTYPES_ACCURATE':
        return <AiFillCheckCircle />;
      default:
      case 'SUGGEST_IMPROVEMENTS':
        return <BsLightningCharge />;
      case 'MANUALLY_EDIT':
      case 'MANUALLY_EDIT_PICO':
      case 'MANUALLY_ENTER_PICO':
        return <FaRegEdit />;
      case 'REGENERATE_PICOT':
        return;
      case 'GENERATE_PICO':
        return <FaMagic />;
      case 'EDIT_PHENOTYPES':
        return <AiOutlineMinusCircle />;
      case 'UNDO_PHENOTYPE_EDITS':
        return <BiUndo />;
      case 'START_OVER':
        return;
    }
  };

  if (suggestionObject && 'suggestions' in suggestionObject) {
    return (
      <>
        <div className="d-md-flex green-bg px-md-6 px-3 py-md-4 py-3">
          <div className="order-logo-container w-10">
            <img src={atroposLogo} alt="logo" />
          </div>

          <div className="w-80">
            {suggestionObject?.reason !== '' && suggestionObject?.reason !== undefined ? (
              <>
                {suggestionObject?.reason && (
                  <div className="mb-3">{suggestionObject?.reason}</div>
                )}
              </>
            ) : (
              <>
                {suggestionObject?.display_text_1 && (
                  <div className="mb-3">{suggestionObject.display_text_1}</div>
                )}

                {suggestions &&
                  Array.isArray(suggestions) &&
                  suggestions.length > 0 &&
                  suggestions.map((suggestion, index) => (
                    <div className="mb-1" key={suggestion.id}>
                      <strong>
                        {index + 1}. {suggestion.name} ({suggestion.field}):{' '}
                      </strong>
                      <span>{suggestion.description}</span>
                    </div>
                  ))}
                {suggestionObject?.display_text_2 && (
                  <div className="mt-3">{suggestionObject.display_text_2}</div>
                )}
              </>
            )}
          </div>
        </div>
        {!selectedSuggestionsOptions ? (
          <div className="green-border">
            <div className="flex-wrap flex-justify-end px-md-2 px-2 d-md-flex pt-3">
              {buttons && Object.keys(buttons).length > 0
                ? Object.entries(buttons).map(([key, value], index) => (
                    <React.Fragment key={value}>
                      {suggestions &&
                      Array.isArray(suggestions) &&
                      suggestions.length > 0 ? (
                        <ButtonCustom
                          cssClass={`blue-btn mb-3 mx-md-1 mx-0 ${
                            options.includes(`${index + 1}. ${value}`) && 'active-bg'
                          }`}
                          buttonAction={`${value} button clicked`}
                          id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                          onClick={() => handleButtonClick(`${index + 1}. ${value}`, key)}
                        >
                          <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                            <span>{`${index + 1}. ${value}`}</span>
                          </div>
                        </ButtonCustom>
                      ) : (
                        <ButtonCustom
                          cssClass={`blue-btn mb-3 mx-md-1 mx-0 ${
                            options.includes(`${index + 1}. ${value}`) && 'active-bg'
                          }`}
                          buttonAction={`${value} button clicked`}
                          id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                          onClick={() => handleButtonClick(value, key)}
                        >
                          <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                            <span>{value}</span>
                          </div>
                        </ButtonCustom>
                      )}
                    </React.Fragment>
                  ))
                : Object.entries(defaultButtons).map(([key, value]) => (
                    <React.Fragment key={value}>
                      <ButtonCustom
                        cssClass="blue-btn mb-3 mx-md-1 mx-0"
                        buttonAction={`${value} button clicked`}
                        id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                        onClick={() => handleButtonClick(value, key)}
                      >
                        <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                          {showButtonIcons(key)}
                          <span>{value}</span>
                        </div>
                      </ButtonCustom>
                    </React.Fragment>
                  ))}
            </div>
            <div className="flex-wrap flex-justify-end px-md-2 px-2 d-md-flex">
              {suggestions &&
                Array.isArray(suggestions) &&
                suggestions.length > 0 &&
                customButtons &&
                buttons &&
                Object.keys(buttons).length > 0 &&
                Object.entries(customButtons).map(([key, value], index) => (
                  <React.Fragment key={value}>
                    <ButtonCustom
                      cssClass={`blue-btn mb-3 mx-md-1 mx-0 ${
                        key === 'INCORPORATE_CHANGES' && options.length === 0
                          ? 'btn-disabled'
                          : ''
                      }`}
                      buttonAction={`${value} button clicked`}
                      id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                      onClick={() => handleButtonClick(value, key)}
                      disabled={key === 'INCORPORATE_CHANGES' && options.length === 0}
                    >
                      <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                        <span>{value}</span>
                      </div>
                    </ButtonCustom>
                  </React.Fragment>
                ))}
            </div>
          </div>
        ) : (
          <div className="flex-wrap flex-justify-end green-border px-md-2 px-2 d-md-flex pt-3">
            {selectedSuggestionsOptions &&
              Object.entries(selectedSuggestionsOptions).map(([key, value], index) => (
                <React.Fragment key={value}>
                  <ButtonCustom
                    cssClass={`active-bg blue-btn mb-3 mx-md-1 mx-0`}
                    buttonAction={`${value} button clicked`}
                    id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                    onClick={() => {
                      return;
                    }}
                  >
                    <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                      {(value === aiButtonsEnum.KEEP_ORIGINAL ||
                        value === aiButtonsEnum.REGENERATE_SUGGESTIONS) && (
                        <AiFillCheckCircle />
                      )}
                      <span>{value}</span>
                    </div>
                  </ButtonCustom>
                </React.Fragment>
              ))}
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {isEdit ? (
          <PICOEditForm
            data={suggestionObject}
            display_text_1={suggestionObject?.display_text_1}
            display_text_2={suggestionObject?.display_text_2}
            suggestionObject={suggestionObject}
            index={index}
            picoFormState={suggestionObject}
            onPICOSaveForm={onPICOSaveForm}
            dispatchSuggestionAction={dispatch}
          />
        ) : (
          <div className="d-md-flex green-bg px-md-6 px-3 py-md-4 py-3">
            <div className="order-logo-container w-10">
              <img src={atroposLogo} alt="logo" />
            </div>

            <div className="w-80">
              <>
                {suggestionObject?.reason !== '' &&
                suggestionObject?.reason !== undefined ? (
                  <div className="mb-3">{suggestionObject?.reason}</div>
                ) : (
                  <>
                    {suggestionObject?.display_text_1 && (
                      <div
                        className="mb-3"
                        dangerouslySetInnerHTML={{
                          __html: suggestionObject?.display_text_1,
                        }}
                      />
                    )}
                  </>
                )}

                {suggestionObject?.study_format &&
                  Object.entries(suggestionObject?.study_format).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <PicotDataDisplay
                        updated_pico={updated_pico}
                        data={{ key, value }}
                      />
                    </React.Fragment>
                  ))}

                {suggestionObject?.display_text_2 && (
                  <div className="mt-3">{suggestionObject.display_text_2}</div>
                )}
              </>
            </div>
          </div>
        )}

        {!isEdit && !selectedSuggestionsOptions ? (
          <div className="flex-wrap flex-justify-end green-border px-md-2 px-2 d-md-flex pt-3">
            {buttons && Object.keys(buttons).length > 0
              ? Object.entries(buttons).map(
                  ([key, value]) =>
                    ((index !== 0 && key !== aiButtonsEnum.REGENERATE_PICOT) ||
                      index === 0) && (
                      <React.Fragment key={value}>
                        {key === aiButtonsEnum.PICOT_ACCURATE && (
                          <div className="flex-basis-100"> </div>
                        )}
                        <ButtonCustom
                          cssClass="blue-btn mb-3 mx-md-1 mx-0"
                          buttonAction={`${value} button clicked`}
                          id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                          onClick={() => handleButtonClick(value, key)}
                        >
                          <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                            {showButtonIcons(key)}
                            <span>{value}</span>
                          </div>
                        </ButtonCustom>
                      </React.Fragment>
                    ),
                )
              : Object.entries(defaultButtons).map(([key, value]) => (
                  <React.Fragment key={value}>
                    <ButtonCustom
                      cssClass="blue-btn mb-3 mx-md-1 mx-0"
                      buttonAction={`${value} button clicked`}
                      id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                      onClick={() => handleButtonClick(value, key)}
                    >
                      <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                        {showButtonIcons(key)}
                        <span>{value}</span>
                      </div>
                    </ButtonCustom>
                  </React.Fragment>
                ))}
          </div>
        ) : (
          !isEdit && (
            <div className="flex-wrap flex-justify-end green-border px-md-2 px-2 d-md-flex pt-3">
              {selectedSuggestionsOptions &&
                Object.entries(selectedSuggestionsOptions).map(([key, value], index) => (
                  <React.Fragment key={value}>
                    <ButtonCustom
                      cssClass={`active-bg blue-btn mb-3 mx-md-1 mx-0`}
                      buttonAction={`${value} button clicked`}
                      id={`btn-${value.toLowerCase().replace(/\s+/g, '-')}-button`}
                      onClick={() => {
                        return;
                      }}
                    >
                      <div className="icon-button d-flex flex-justify-center flex-items-center position-relative">
                        <AiFillCheckCircle />
                        <span>{value}</span>
                      </div>
                    </ButtonCustom>
                  </React.Fragment>
                ))}
            </div>
          )
        )}
      </>
    );
  }
}

export default StudyDetails;
